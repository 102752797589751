.card-login {
  background-color: #6294d6;
  color: white;
  border-radius: 0%;
  border-color: #6294d6;
}
.card-login input[type="text"],
.card-login input[type="password"]{
  border: 1px solid #6294d6;
  height: 50px;
  font-weight: 900;
  font-size: 18px;

}
.card-login input[type="checkbox"] {
  border: 1px solid #6294d6;
}
.card-login input[type="radio"],
.card-login input[type="checkbox"] {
  display: none;
}
.card-login label {
  cursor: pointer;
  padding-top: 8px;
  /* letter-spacing: 5px; */
  line-height: 17px;
}
.card-login input[type="radio"] + label:before,
.card-login input[type="checkbox"] + label:before {
  border: 1px solid #fff;
  background: #fff;
  content: "";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 20px;
}
.card-login input[type="radio"]:checked + label:before,
.card-login input[type="checkbox"]:checked + label:before {
  background: #466895;
  color: #fff;
  content: "";
  text-align: center;
}
.card-login input[type="radio"]:checked + label:after,
.card-login input[type="checkbox"]:checked + label:after {
  font-weight: bold;
}
.card-login input[type="radio"] {
  border: 1px solid #6294d6 !important;
}
.card-login a {
  color: white;
}
.card-login button {
  color: white;
  background-color: #466895;
  border: 1px solid #466895;
  border-radius: 0%;
  font-weight: bolder;
}
.card-login button:hover,
.card-login button:focus,
.card-login button:active,
.card-login button:visited {
  background-color: #e08c58 !important;
  border: 1px solid #e08c58 !important;
}
.login {
  background: url("../images/patter-1-2.jpg") #466895 repeat;
}
