/***************************************************************************************************************
||||||||||||||||||||||||||||            MASTER STYLESHEET FOR KIPSO         ||||||||||||||||||||||||||||||||||||
****************************************************************************************************************
||||||||||||||||||||||||||||              TABLE OF CONTENT                  ||||||||||||||||||||||||||||||||||||
****************************************************************************************************************
****************************************************************************************************************
* 1. common styles
* 2. header styles
* 3. slider styles
* 4. contact styles
* 5. gallery styles
* 6. faq styles
* 7. cta styles
* 8. team styles
* 9. pricing styles
* 10. become-teacher styles
* 11. course styles
* 12. inner-banner styles
* 13. footer styles
* 14. blog styles
* 15. search-popup styles
* 16. sidebar styles
* 17. about styles
* 18. video styles
* 19. brand styles
* 20. testimonials styles
* 21. meeting styles
* 22. countdown styles
* 23. mailchimp styles
****************************************************************************************************************
||||||||||||||||||||||||||||            End TABLE OF CONTENT                ||||||||||||||||||||||||||||||||||||
****************************************************************************************************************/

/*
* 1. common styles
*/

body {
  font-family: 'Fira Sans Condensed', sans-serif;
  color: #808080;
  font-size: 16px;
  line-height: 34px;
  letter-spacing: -0.02em;
}
.box-conteudo {
  border: 4px #466895;
  border-style: double;
}



a:active, a:hover, a:focus, a:visited {
  text-decoration: none;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

.thm-base-bg {
  background-color: #f16101;
}

.thm-base-bg-2 {
  background-color: #2da397;
}

.thm-gray-bg {
  background-color: #f1f1f1;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.block-title {
  text-align: center;
  margin-bottom: 60px;
}

.block-title__title {
  margin: 0;
  color: #466895;
  font-size: 50px;
  font-weight: bold;
  margin-bottom: -15px;
  margin-top: -15px;
}

.thm-btn {
  display: inline-block;
  vertical-align: middle;
  border: none;
  outline: none;
  background-color: #466895;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  padding: 6.5px 27px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  font-size: 36px;
}

.thm-btn:hover {
  background-color: #f16101;
  color: #012237;
}

.post-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.post-pagination a {
  border-radius: 50%;
  background-color: #f1f1f1;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  padding: 8px 19px;
  color: #012237;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.post-pagination a:hover, .post-pagination a.active {
  background-color: #012237;
  color: #fff;
}

.post-pagination a+a {
  margin-left: 15px;
}

.tooltip-inner {
  -webkit-box-shadow: 5px 8.66px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 8.66px 30px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #808080;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 48px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
  border-top-color: #fff;
}

.preloader {
  position: fixed;
  background-color: #fff;
  background-image: url(../images/preloader.gif);
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9991;
}

.scroll-to-top {
  display: inline-block;
  width: 45px;
  height: 45px;
  background: #f16101;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99;
  text-align: center;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  display: none;
  border-radius: 50%;
  transition: all .4s ease;
}

.scroll-to-top i {
  color: #fff;
  font-size: 18px;
  line-height: 45px;
}

.scroll-to-top:hover {
  background-color: #012237;
}

.scroll-to-top:hover i {
  color: #fff;
}

/* Cursor Style */

.cursor {
  position: absolute;
  background-color: #fff;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  z-index: 1;
  -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  -webkit-transform: scale(1);
  transform: scale(1);
  visibility: hidden;
}

.cursor {
  visibility: visible;
}

.cursor.active {
  opacity: 0.5;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.cursor.hovered {
  opacity: 0.08;
}

.cursor-follower {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  z-index: 1;
  -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  visibility: hidden;
}

.cursor-follower {
  visibility: visible;
}

.cursor-follower.active {
  opacity: 0.7;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.cursor-follower.hovered {
  opacity: 0.08;
}

.cursor-follower.close-cursor:before {
  position: absolute;
  content: '';
  height: 25px;
  width: 2px;
  background: #fff;
  left: 48%;
  top: 12px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  display: inline-block;
}

.cursor-follower.close-cursor:after {
  position: absolute;
  content: '';
  height: 25px;
  width: 2px;
  background: #fff;
  right: 48%;
  top: 12px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*
* 2. header styles
*/

.topbar-one {
  background-color: #286ec9;
}

.topbar-one .container {
  padding-top: 6.5px;
  padding-bottom: 6.5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.topbar-one__right, .topbar-one__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.plano-titulo:before {
  position: relative;
  height: 5px;
  width: 30%;
  left: 0;
  top: 50px;
  background: #ffd786;
  display: block;
  content: "";
}

.incentivo {
  display: flex;
  vertical-align: baseline;
}

.divisoria {
  height: 140px;
  font-weight: bolder;
  border-right-style: solid;
  border-right-width: 2px;
  border-color: #6d6e70;
  padding: 0 15px;
  vertical-align: middle;
  /* display: table-cell; */
  padding-top: 14%;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-17 {
  font-size: 17px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-19 {
  font-size: 19px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-21 {
  font-size: 21px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-23 {
  font-size: 23px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-25 {
  font-size: 25px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-27 {
  font-size: 27px;
}

.font-size-28 {
  font-size: 28px;
}

.font-size-29 {
  font-size: 29px;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-31 {
  font-size: 31px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-33 {
  font-size: 33px;
}

.font-size-34 {
  font-size: 34px;
}

.font-size-35 {
  font-size: 35px;
}

.font-size-36 {
  font-size: 36px;
}

.font-size-37 {
  font-size: 37px;
}

.font-size-38 {
  font-size: 38px;
}

.font-size-39 {
  font-size: 39px;
}

.font-size-40 {
  font-size: 40px;
}

.font-size-41 {
  font-size: 41px;
}

.font-size-42 {
  font-size: 42px;
}

.font-size-43 {
  font-size: 43px;
}

.font-size-44 {
  font-size: 44px;
}

.font-size-45 {
  font-size: 45px;
}

.font-size-46 {
  font-size: 46px;
}

.font-size-47 {
  font-size: 47px;
}

.font-size-48 {
  font-size: 48px;
}

.font-size-49 {
  font-size: 49px;
}

.font-size-50 {
  font-size: 50px;
}

.font-size-51 {
  font-size: 51px;
}

.font-size-52 {
  font-size: 52px;
}

.font-size-53 {
  font-size: 53px;
}

.font-size-54 {
  font-size: 54px;
}

.font-size-55 {
  font-size: 55px;
}

.font-size-56 {
  font-size: 56px;
}

.font-size-57 {
  font-size: 57px;
}

.font-size-58 {
  font-size: 58px;
}

.font-size-59 {
  font-size: 59px;
}

.font-size-60 {
  font-size: 60px;
}

.font-size-61 {
  font-size: 61px;
}

.font-size-62 {
  font-size: 62px;
}

.font-size-63 {
  font-size: 63px;
}

.font-size-64 {
  font-size: 64px;
}

.font-size-65 {
  font-size: 65px;
}

.font-size-66 {
  font-size: 66px;
}

.font-size-67 {
  font-size: 67px;
}

.font-size-68 {
  font-size: 68px;
}

.font-size-69 {
  font-size: 69px;
}

.font-size-70 {
  font-size: 70px;
}

.font-size-71 {
  font-size: 71px;
}

.font-size-72 {
  font-size: 72px;
}

.font-size-73 {
  font-size: 73px;
}

.font-size-74 {
  font-size: 74px;
}

.font-size-75 {
  font-size: 75px;
}

.lh-11 {
  line-height: 11px;
}

.lh-12 {
  line-height: 12px;
}

.lh-13 {
  line-height: 13px;
}

.lh-14 {
  line-height: 14px;
}

.lh-15 {
  line-height: 15px;
}

.lh-16 {
  line-height: 16px;
}

.lh-17 {
  line-height: 17px;
}

.lh-18 {
  line-height: 18px;
}

.lh-19 {
  line-height: 19px;
}

.lh-20 {
  line-height: 20px;
}

.lh-21 {
  line-height: 21px;
}

.lh-22 {
  line-height: 22px;
}

.lh-23 {
  line-height: 23px;
}

.lh-24 {
  line-height: 24px;
}

.lh-25 {
  line-height: 25px;
}

.lh-26 {
  line-height: 26px;
}

.lh-27 {
  line-height: 27px;
}

.lh-28 {
  line-height: 28px;
}

.lh-29 {
  line-height: 29px;
}

.lh-30 {
  line-height: 30px;
}

.lh-31 {
  line-height: 31px;
}

.lh-32 {
  line-height: 32px;
}

.lh-33 {
  line-height: 33px;
}

.lh-34 {
  line-height: 34px;
}

.lh-35 {
  line-height: 35px;
}

.lh-36 {
  line-height: 36px;
}

.lh-37 {
  line-height: 37px;
}

.lh-38 {
  line-height: 38px;
}

.lh-39 {
  line-height: 39px;
}

.lh-40 {
  line-height: 40px;
}

.lh-41 {
  line-height: 41px;
}

.lh-42 {
  line-height: 42px;
}

.lh-43 {
  line-height: 43px;
}

.lh-44 {
  line-height: 44px;
}

.lh-45 {
  line-height: 45px;
}

.lh-46 {
  line-height: 46px;
}

.lh-47 {
  line-height: 47px;
}

.lh-48 {
  line-height: 48px;
}

.lh-49 {
  line-height: 49px;
}

.lh-50 {
  line-height: 50px;
}

.lh-51 {
  line-height: 51px;
}

.lh-52 {
  line-height: 52px;
}

.lh-53 {
  line-height: 53px;
}

.lh-54 {
  line-height: 54px;
}

.lh-55 {
  line-height: 55px;
}

.lh-56 {
  line-height: 56px;
}

.lh-57 {
  line-height: 57px;
}

.lh-58 {
  line-height: 58px;
}

.lh-59 {
  line-height: 59px;
}

.lh-60 {
  line-height: 60px;
}

.lh-61 {
  line-height: 61px;
}

.lh-62 {
  line-height: 62px;
}

.lh-63 {
  line-height: 63px;
}

.lh-64 {
  line-height: 64px;
}

.lh-65 {
  line-height: 65px;
}

.lh-66 {
  line-height: 66px;
}

.lh-67 {
  line-height: 67px;
}

.lh-68 {
  line-height: 68px;
}

.lh-69 {
  line-height: 69px;
}

.lh-70 {
  line-height: 70px;
}

.lh-71 {
  line-height: 71px;
}

.lh-72 {
  line-height: 72px;
}

.lh-73 {
  line-height: 73px;
}

.lh-74 {
  line-height: 74px;
}

.lh-75 {
  line-height: 75px;
}

.fase-one {
  color: #6d6e70
}

.link {
  color: #808080;
  text-decoration: underline;
}

.topbar-one__right a, .topbar-one__left a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  -webkit-transition: color .4s ease;
  transition: color .4s ease;
}

.topbar-one__right a:hover, .topbar-one__left a:hover {
  color: #f16101;
}

.topbar-one__right a+a, .topbar-one__left a+a {
  margin-left: 25px;
}

.header-navigation {
  background-color: transparent;
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  padding: 0;
  position: relative;
  background-color: transparent;
}

.header-navigation .container {
  background: transparent;
  position: relative;
  display: block;
}

.header-navigation .container .logo-box {
  float: left;
  /* position: absolute; */
  top: 50%;
  left: 15px;
  /* -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox; */
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-navigation .container .navbar-brand {
  height: auto;
  margin: 0;
  line-height: 1;
}

.header-navigation .container .menu-toggler {
  display: none;
}

.header-navigation .container .right-side-box {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
}

.header-navigation .main-navigation {
  float: none;
  text-align: right;
  padding-right: 90px;
}

@media (min-width: 1200px) {
  .header-navigation .main-navigation {
    display: block !important;
  }
}

.header-navigation ul.navigation-box {
  margin: 0;
  padding: 0;
  list-style: none;
}

.header-navigation ul.navigation-box li a .sub-nav-toggler {
  display: none;
}

.header-navigation ul.navigation-box>li {
  position: relative;
  padding: 35px 0;
  display: inline-block;
  vertical-align: middle;
  /* Second Level Menu */
  /* Thrid Level Menu */
}

.header-navigation ul.navigation-box>li+li {
  margin-left: 48px;
}

.header-navigation ul.navigation-box>li:first-child {
  padding-left: 0;
}

.header-navigation ul.navigation-box>li:last-child {
  padding-right: 0;
}

.header-navigation ul.navigation-box>li>a {
  padding: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  position: relative;
  text-transform: capitalize;
  letter-spacing: -0.02em;
  font-size: 18px;
  font-weight: 500;
  color: #808080;
  position: relative;
}

.header-navigation ul.navigation-box>li>a.current, .header-navigation ul.navigation-box>li:hover>a {
  color: #cccccc;
}

.header-navigation ul.navigation-box>li>.sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 911;
  float: left;
  min-width: 220px;
  padding: 0px 0px;
  text-align: left;
  list-style: none;
  background-color: #012237;
  background-clip: padding-box;
  opacity: 0;
  border-radius: 0px;
  visibility: hidden;
  -webkit-transition: opacity .4s ease, visibility .4s ease;
  transition: opacity .4s ease, visibility .4s ease;
  -webkit-box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
}

@media (min-width: 1200px) {
  .header-navigation ul.navigation-box>li>.sub-menu {
    display: block !important;
  }
}

.header-navigation ul.navigation-box>li>.sub-menu.right-align {
  left: auto;
  right: 0;
}

.header-navigation ul.navigation-box>li>.sub-menu.center-align {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.header-navigation ul.navigation-box>li>.sub-menu>li {
  display: block;
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.header-navigation ul.navigation-box>li>.sub-menu>li+li {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.header-navigation ul.navigation-box>li>.sub-menu>li>a {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  padding: 12px 30px;
  display: block;
  line-height: 26px;
  white-space: nowrap;
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.header-navigation ul.navigation-box>li>.sub-menu>li:hover>a {
  color: #fff;
  background: #f16101;
}

.header-navigation ul.navigation-box>li:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
}

.header-navigation ul.navigation-box>li:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
}

.header-navigation ul.navigation-box>li>ul>li {
  /* no more nested showen */
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu {
  position: absolute;
  top: 0%;
  left: 100%;
  z-index: 911;
  float: left;
  min-width: 220px;
  padding: 0px 0px;
  text-align: left;
  list-style: none;
  background-color: #012237;
  background-clip: padding-box;
  opacity: 0;
  border-radius: 0px;
  visibility: hidden;
  -webkit-transition: opacity .4s ease, visibility .4s ease;
  transition: opacity .4s ease, visibility .4s ease;
  -webkit-box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
}

@media (min-width: 1200px) {
  .header-navigation ul.navigation-box>li>ul>li>.sub-menu {
    display: block !important;
  }
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu.right-align {
  left: auto;
  right: 100%;
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu.center-align {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu>li {
  display: block;
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu>li+li {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu>li>a {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  padding: 12px 30px;
  display: block;
  line-height: 26px;
  white-space: nowrap;
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu>li:hover>a {
  color: #fff;
  background: #f16101;
}

.header-navigation ul.navigation-box>li>ul>li:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
}

.header-navigation ul.navigation-box>li>ul>li ul {
  display: none;
}

.stricked-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  -webkit-box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-110%);
  transform: translateY(-110%);
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.stricked-menu ul.navigation-box>li {
  padding-top: 18px;
  padding-bottom: 18px;
}

.stricked-menu.stricky-fixed {
  opacity: 1;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.site-header {
  position: relative;
}

.site-header__decor {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  z-index: 99;
}

.site-header__decor-row {
  margin-left: -7px;
  margin-right: -7px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.site-header__decor-single {
  padding-left: 7px;
  padding-right: 7px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 33.33333%;
  flex: 1 1 33.33333%;
  max-width: 33.33333%;
}

[class*=site-header__decor-inner-] {
  position: relative;
}

[class*=site-header__decor-inner-]::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #e08c58;
  opacity: 0.9;
}

.site-header__decor-inner-2::before {
  background-color: #466895;
}

.site-header__decor-inner-3::before {
  background-color: #6294d6;
}

.header__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 90px;
  margin-top: 5px;
}

.header__social a {
  color: #808080;
  font-size: 18px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.header__social a:hover {
  color: #f16101;
}

.header__social a+a {
  margin-left: 40px;
}

.header__search-btn {
  width: 45px;
  height: 45px;
  border-radius: 56%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #466895;
  font-size: 18px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  position: relative;
  color: #fff;
}

.header__search-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -5px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* border: 1px solid #2da397; */
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.header__search-btn:hover {
  color: #fff;
}

.header__search-btn:hover:before {
  left: 0;
}

.header__search-form {
  background-color: #f1f1f1;
  width: 100%;
  max-width: 410px;
  height: 68px;
  position: relative;
  margin-left: 60px;
}

.header__search-form input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  padding-left: 30px;
}

.header__search-form button[type=submit] {
  border: none;
  outline: none;
  width: 46px;
  height: 46px;
  border-radius: 56%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f16101;
  font-size: 18px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  position: absolute;
  color: #fff;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
}

.header__search-form button[type=submit]::before {
  content: '';
  position: absolute;
  top: 0;
  left: -5px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #f16101;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.header__search-form button[type=submit]:hover {
  color: #fff;
}

.header__search-form button[type=submit]:hover:before {
  left: 0;
}

@media (min-width: 1200px) {
  .site-header__header-two .container {
    padding-left: 60px;
    padding-right: 60px;
    max-width: 100%;
  }
}

.site-header__header-two .header-navigation .container .logo-box {
  left: 60px;
  -webkit-box-flex: wrap;
  -ms-flex: wrap;
  flex: wrap;
  width: 100%;
}

.site-header__header-two .header-navigation .container .right-side-box {
  right: 60px;
}

.site-header__header-two .header-navigation ul.navigation-box>li {
  padding: 55px 0;
}

.site-header__header-two .header-navigation .main-navigation {
  text-align: center;
  padding-right: 0;
  padding-left: 125px;
}

.site-header__header-two .header__social {
  margin: 0;
}

.site-header__header-two .stricked-menu ul.navigation-box>li {
  padding-top: 40px;
  padding-bottom: 40px;
}

.site-header__home-three {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  border-bottom: 2px solid rgba(255, 255, 255, 0.05);
}

.site-header__home-three .topbar-one {
  background-color: #2da397;
}

.site-header__home-three .header-navigation .main-navigation {
  text-align: center;
  padding-right: 0;
}

.site-header__home-three .header__social a, .site-header__home-three .header-navigation ul.navigation-box>li>a {
  color: #fff;
}

.site-header__home-three .header__social a:hover, .site-header__home-three .header-navigation ul.navigation-box>li>a:hover {
  color: #f16101;
}

.site-header__home-three .header-navigation ul.navigation-box>li>a.current, .site-header__home-three .header-navigation ul.navigation-box>li:hover>a {
  color: #f16101;
}

.site-header__home-three .stricked-menu {
  background-color: #012237;
}

.site-header__home-three .header__social {
  margin: 0;
}

.site-header__inner-page .header-navigation .main-navigation {
  padding-right: 55px;
}

.site-header__inner-page .header__search-btn {
  background-color: transparent;
  color: #012237;
}

.site-header__inner-page .header__search-btn::before {
  display: none;
}

/*
* 3. slider styles
*/

.banner-wrapper {
  position: relative;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .banner-wrapper {
    overflow: hidden;
  }
}

.banner-wrapper .owl-dots {
  margin: 0;
  position: absolute;
  bottom: 60px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  z-index: 999;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1200px;
  text-align: right;
  display: none !important;
}

.banner-wrapper .owl-dots .owl-dot {
  position: relative;
  z-index: 991;
}

.banner-wrapper .owl-dots .owl-dot span {
  width: 13px;
  height: 13px;
  border: 2px solid #fff;
  background-color: transparent;
  opacity: .2;
  margin: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.banner-wrapper .owl-dots .owl-dot+.owl-dot {
  margin-left: 7px;
}

.banner-wrapper .owl-dots .owl-dot.active span {
  opacity: 1;
  background-color: transparent;
}

.banner-wrapper .owl-carousel .owl-item img {
  width: auto;
}

.banner-wrapper .owl-theme .owl-nav {
  display: none;
  margin: 0;
}

.owl-prev, .owl-next, .banner-wrapper .banner-carousel-btn [class*=banner-carousel-btn__] {
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  background-color: #466895;
  color: #fff;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 91;
  border: 1px solid #466895;
}

.banner-wrapper .banner-carousel-btn [class*=banner-carousel-btn__] i {
  font-size: 22px;
}

.banner-wrapper .banner-carousel-btn [class*=banner-carousel-btn__]::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #466895;
  border-radius: 50%;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.banner-carousel-btn__left-btn {
  left: 5%;
}

.banner-carousel-btn__left-btn:before {
  left: 0px;
}

.banner-carousel-btn__left-btn:hover:before {
  left: 0;
}

.banner-carousel-btn__right-btn {
  right: 5%;
}

.banner-carousel-btn__right-btn:before {
  right: 0px;
}

.banner-carousel-btn__right-btn:hover:before {
  right: 0;
}

.banner-one .container {
  position: relative;
  padding-top: 200px;
  padding-bottom: 200px;
}

.banner-one__slide {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #ffd786;
}

.banner-one__slide:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../images/patter-1-1.jpg);
  opacity: 0.8;
}

.banner-one__light-color {
  color: #fff;
}

.banner-one__title {
  font-size: 140px;
  line-height: 120px;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: -.04em;
  margin: 0;
  margin-bottom: 40px;
  text-transform: uppercase;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

.banner-one__tag-line {
  margin: 0;
  font-size: 50px;
  font-family: "Satisfy";
  color: white;
  text-decoration: underline;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  margin-bottom: 55px;
}

.banner-one__btn {
  font-size: 18px;
  padding: 21.5px 59.5px;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

.banner-one__person {
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(255, 255, 255, 0.05);
  box-shadow: 0px 0px 40px 0px rgba(255, 255, 255, 0.05);
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

.banner-one__scratch {
  position: absolute;
  right: -5%;
  top: 20%;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

.banner-one [class*=banner-one__bubble-] {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

.banner-one__bubble-1 {
  position: absolute;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-color: #1888c9;
  top: 20%;
  left: -3%;
  -webkit-animation-name: bubbleMoveOne;
  animation-name: bubbleMoveOne;
  -webkit-transform-origin: top left;
  transform-origin: top left;
}

.banner-one__bubble-2 {
  position: absolute;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #7c4bc0;
  right: 35%;
  top: 20%;
  -webkit-animation-name: bubbleMoveTwo;
  animation-name: bubbleMoveTwo;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.banner-one__bubble-3 {
  position: absolute;
  width: 106px;
  height: 106px;
  border-radius: 50%;
  border: 24px solid #33a9bb;
  right: 35%;
  bottom: 10%;
  -webkit-animation-name: globeRotate;
  animation-name: globeRotate;
  -webkit-animation-duration: 10s !important;
  animation-duration: 10s !important;
}

.banner-one .active .banner-one__title {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.banner-one .active .banner-one__tag-line {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
}

.banner-one .active .banner-one__btn {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.banner-one .active .banner-one__person {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.banner-one .active .banner-one__scratch {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.banner-one__cta {
  width: 100%;
  max-width: 544px;
  background-color: #2da397;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 91;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 50px 0;
  padding-left: 60px;
  padding-right: 60px;
}

.banner-one__cta-icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  background-color: #fff;
  position: relative;
}

.banner-one__cta-icon:before {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  left: -10px;
  top: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.banner-one__cta-icon:hover:before {
  left: 0;
}

.banner-one__cta-icon i {
  line-height: 70px;
  color: #2da397;
  font-size: 38px;
}

.banner-one__cta-title {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0;
  padding-left: 30px;
}

.banner-one__cta-text {
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  font-weight: 500;
  font-style: italic;
  margin: 0;
}

.banner-one__cta a {
  color: inherit;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.banner-one__cta a:hover {
  color: #f16101;
}

.banner-one__cta-link {
  color: #fff;
  font-size: 32px;
}

.banner-two .container {
  position: relative;
  padding-top: 200px;
  padding-bottom: 245px;
}

.banner-two__slide {
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.banner-two__light-color {
  color: #fff;
}

.banner-two__title {
  font-size: 100px;
  line-height: 100px;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: -.04em;
  margin: 0;
  margin-bottom: 60px;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

.banner-two__tag-line {
  margin: 0;
  font-size: 40px;
  font-family: "Satisfy";
  color: white;
  text-decoration: underline;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  margin-bottom: 30px;
}

.banner-two__btn {
  font-size: 18px;
  padding: 21.5px 59.5px;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

.banner-two .active .banner-two__title {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.banner-two .active .banner-two__tag-line {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
}

.banner-two .active .banner-two__btn {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes bubbleMoveOne {
  0% {
    -webkit-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
  20% {
    -webkit-transform: translateX(20px) translateY(-20px);
    transform: translateX(20px) translateY(-20px);
  }
  40% {
    -webkit-transform: translateX(40px) translateY(-40px);
    transform: translateX(40px) translateY(-40px);
  }
  80% {
    -webkit-transform: translateX(20px) translateY(-20px);
    transform: translateX(20px) translateY(-20px);
  }
  100% {
    -webkit-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}

@keyframes bubbleMoveOne {
  0% {
    -webkit-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
  20% {
    -webkit-transform: translateX(20px) translateY(-20px);
    transform: translateX(20px) translateY(-20px);
  }
  40% {
    -webkit-transform: translateX(40px) translateY(-40px);
    transform: translateX(40px) translateY(-40px);
  }
  80% {
    -webkit-transform: translateX(20px) translateY(-20px);
    transform: translateX(20px) translateY(-20px);
  }
  100% {
    -webkit-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}

@-webkit-keyframes bubbleMoveTwo {
  0% {
    -webkit-transform: scale(1) translateX(0px) translateY(0px);
    transform: scale(1) translateX(0px) translateY(0px);
  }
  20% {
    -webkit-transform: scale(1.1) translateX(20px) translateY(20px);
    transform: scale(1.1) translateX(20px) translateY(20px);
  }
  40% {
    -webkit-transform: scale(1.2) translateX(40px) translateY(40px);
    transform: scale(1.2) translateX(40px) translateY(40px);
  }
  80% {
    -webkit-transform: scale(1.1) translateX(20px) translateY(20px);
    transform: scale(1.1) translateX(20px) translateY(20px);
  }
  100% {
    -webkit-transform: scale(1) translateX(0px) translateY(0px);
    transform: scale(1) translateX(0px) translateY(0px);
  }
}

@keyframes bubbleMoveTwo {
  0% {
    -webkit-transform: scale(1) translateX(0px) translateY(0px);
    transform: scale(1) translateX(0px) translateY(0px);
  }
  20% {
    -webkit-transform: scale(1.1) translateX(20px) translateY(20px);
    transform: scale(1.1) translateX(20px) translateY(20px);
  }
  40% {
    -webkit-transform: scale(1.2) translateX(40px) translateY(40px);
    transform: scale(1.2) translateX(40px) translateY(40px);
  }
  80% {
    -webkit-transform: scale(1.1) translateX(20px) translateY(20px);
    transform: scale(1.1) translateX(20px) translateY(20px);
  }
  100% {
    -webkit-transform: scale(1) translateX(0px) translateY(0px);
    transform: scale(1) translateX(0px) translateY(0px);
  }
}

@-webkit-keyframes globeRotate {
  8% {
    -webkit-transform: perspective(200px) rotateX(0deg) rotateY(0);
    transform: perspective(200px) rotateX(0deg) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(200px) rotateX(360deg);
    transform: perspective(200px) rotateX(360deg);
  }
  51% {
    -webkit-transform: perspective(200px) rotateX(0deg) rotateY(0);
    transform: perspective(200px) rotateX(0deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(200px) rotateY(360deg);
    transform: perspective(200px) rotateY(360deg);
  }
}

@keyframes globeRotate {
  8% {
    -webkit-transform: perspective(200px) rotateX(0deg) rotateY(0);
    transform: perspective(200px) rotateX(0deg) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(200px) rotateX(360deg);
    transform: perspective(200px) rotateX(360deg);
  }
  51% {
    -webkit-transform: perspective(200px) rotateX(0deg) rotateY(0);
    transform: perspective(200px) rotateX(0deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(200px) rotateY(360deg);
    transform: perspective(200px) rotateY(360deg);
  }
}

.slider-three {
  padding-top: 310px;
  padding-bottom: 210px;
  margin-top: 47px;
  position: relative;
  background-color: #012237;
}

.slider-three__title {
  font-size: 80px;
  line-height: 80px;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: -.04em;
  margin: 0;
  margin-bottom: 20px;
}

.slider-three__tag-line {
  margin: 0;
  font-size: 34px;
  font-family: "Satisfy";
  color: #f16101;
  text-decoration: underline;
  margin-bottom: 15px;
}

.slider-three__text {
  margin: 0;
  font-size: 18px;
  line-height: 34px;
  font-weight: 50;
  color: rgba(255, 255, 255, 0.6);
}

.slider-three__search {
  position: relative;
  margin-top: 45px;
}

.slider-three__search input {
  border: none;
  outline: none;
  display: block;
  width: 100%;
  background-color: #fff;
  height: 78px;
  color: #808080;
  font-size: 16px;
  font-weight: 500;
  padding-left: 30px;
}

.slider-three__search button[type=submit] {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: #f16101;
  border: none;
  outline: none;
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.slider-three__search button[type=submit] i {
  font-size: 18px;
  color: #fff;
  line-height: 46px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.slider-three__search button[type=submit]:before {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #f16101;
  position: absolute;
  left: -5px;
  top: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.slider-three__search button[type=submit]:hover:before {
  left: 0;
}

[class*=slider-three__icon-] {
  position: absolute;
}

.slider-three__icon-1 {
  top: 15%;
  left: 10%;
  -webkit-animation-name: bubbleMoveTwo;
  animation-name: bubbleMoveTwo;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

.slider-three__icon-2 {
  bottom: 10%;
  left: 0;
  -webkit-animation-name: bubbleMoveOne;
  animation-name: bubbleMoveOne;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

.slider-three__icon-3 {
  top: 20%;
  left: 45%;
  -webkit-animation-name: globeRotate;
  animation-name: globeRotate;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.slider-three__icon-4 {
  bottom: 15%;
  left: 55%;
  -webkit-animation-name: bubbleMoveOne;
  animation-name: bubbleMoveOne;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

.slider-three__icon-5 {
  top: 15%;
  right: 30%;
  -webkit-animation-name: bubbleMoveTwo;
  animation-name: bubbleMoveTwo;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

/*
* 4. contact styles
*/

.google-map__contact {
  display: block;
  width: 100%;
  height: 560px;
  border: none;
  outline: none;
}

.contact-info-one {
  padding-top: 120px;
}

.contact-info-one__title {
  margin: 0;
  color: #012237;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 55px;
}

.contact-info-one__text {
  margin: 0;
  font-size: 16px;
  line-height: 34px;
  font-weight: 500;
  color: #808080;
  margin-top: -15px;
  margin-bottom: -10px;
}

.contact-info-one__icon {
  width: 112px;
  height: 112px;
  background-color: #f16101;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin-bottom: 25px;
  position: relative;
}

.contact-info-one__icon::before {
  content: '';
  width: 100%;
  height: 100%;
  border: 2px solid #f16101;
  position: absolute;
  top: 0;
  left: -10px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  border-radius: 50%;
}

.contact-info-one__icon i {
  font-size: 54px;
  color: #fff;
}

.contact-info-one [class*=col-lg-]:nth-child(2) .contact-info-one__icon {
  background-color: #2da397;
}

.contact-info-one [class*=col-lg-]:nth-child(2) .contact-info-one__icon::before {
  border-color: #2da397;
}

.contact-info-one [class*=col-lg-]:nth-child(3) .contact-info-one__icon {
  background-color: #7c4bc0;
}

.contact-info-one [class*=col-lg-]:nth-child(3) .contact-info-one__icon::before {
  border-color: #7c4bc0;
}

.contact-info-one__single {
  border-style: solid;
  border-width: 2px;
  border-color: #f1f1f1;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: -webkit-box-shadow .4s ease;
  transition: -webkit-box-shadow .4s ease;
  transition: box-shadow .4s ease;
  transition: box-shadow .4s ease, -webkit-box-shadow .4s ease;
  padding-top: 48px;
  padding-bottom: 48px;
}

.contact-info-one__single:hover {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
}

.contact-info-one__single:hover .contact-info-one__icon:before {
  left: 0;
}

.contact-one {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media (min-width: 1200px) {
  .contact-one .container {
    max-width: 800px;
  }
}

.contact-one__title {
  font-size: 60px;
  font-weight: bold;
  color: #012237;
  margin: 0;
  margin-bottom: 45px;
}

.contact-one input, .contact-one textarea {
  border: none;
  outline: none;
  width: 100%;
  display: block;
  background-color: #f1f1f1;
  height: 77px;
  padding-left: 40px;
  margin-bottom: 10px;
}

.contact-one textarea {
  height: 211px;
  padding-top: 25px;
}

.contact-one .row.low-gutters {
  margin-left: -5px;
  margin-right: -5px;
}

.contact-one .row.low-gutters>[class*=col-] {
  padding-left: 5px;
  padding-right: 5px;
}

.contact-one__btn {
  padding: 22px 72px;
}

.contact-one__btn:hover {
  background-color: #012237;
  color: #fff;
}

/*
* 5. gallery styles
*/

.gallery-one {
  padding-top: 120px;
  padding-bottom: 90px;
}

.gallery-one__single {
  margin-bottom: 30px;
  position: relative;
}

.gallery-one__single>img {
  width: 100%;
}

.gallery-one__single:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f16101;
  opacity: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.gallery-one__single:hover::before {
  opacity: 0.9;
}

.gallery-one__single:hover .gallery-one__popup {
  opacity: 1;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.gallery-one__popup {
  font-size: 46px;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(50%);
  transform: translateX(-50%) translateY(50%);
  -webkit-transition: opacity .4s ease, -webkit-transform .4s ease;
  transition: opacity .4s ease, -webkit-transform .4s ease;
  transition: opacity .4s ease, transform .4s ease;
  transition: opacity .4s ease, transform .4s ease, -webkit-transform .4s ease;
}

.gallery-one__popup i {
  color: #fff;
}

/*
* 6. faq styles
*/

.faq-one {
  padding-top: 120px;
  padding-bottom: 120px;
}

.faq-one [class*=col-lg-] {
  border-bottom: 1px solid #f1f1f1;
  padding-top: 70px;
  padding-bottom: 70px;
}

.faq-one [class*=col-lg-]:nth-child(odd) {
  padding-right: 76px;
}

.faq-one [class*=col-lg-]:nth-child(even) {
  border-left: 1px solid #f1f1f1;
  padding-left: 76px;
}

.faq-one [class*=col-lg-]:nth-child(1), .faq-one [class*=col-lg-]:nth-child(2) {
  padding-top: 0;
}

.faq-one [class*=col-lg-]:nth-child(1) .faq-one__icon span {
  background-color: #1888c9;
}

.faq-one [class*=col-lg-]:nth-child(1) .faq-one__icon span:before {
  border-color: #1888c9;
}

.faq-one [class*=col-lg-]:nth-child(1) .faq-one__icon span {
  background-color: #1888c9;
}

.faq-one [class*=col-lg-]:nth-child(1) .faq-one__icon span:before {
  border-color: #1888c9;
}

.faq-one [class*=col-lg-]:nth-child(2) .faq-one__icon span {
  background-color: #7c4bc0;
}

.faq-one [class*=col-lg-]:nth-child(2) .faq-one__icon span:before {
  border-color: #7c4bc0;
}

.faq-one [class*=col-lg-]:nth-child(3) .faq-one__icon span {
  background-color: #f16101;
}

.faq-one [class*=col-lg-]:nth-child(3) .faq-one__icon span:before {
  border-color: #f16101;
}

.faq-one [class*=col-lg-]:nth-child(4) .faq-one__icon span {
  background-color: #2da397;
}

.faq-one [class*=col-lg-]:nth-child(4) .faq-one__icon span:before {
  border-color: #2da397;
}

.faq-one [class*=col-lg-]:nth-child(5) .faq-one__icon span {
  background-color: #d23f3f;
}

.faq-one [class*=col-lg-]:nth-child(5) .faq-one__icon span:before {
  border-color: #d23f3f;
}

.faq-one [class*=col-lg-]:nth-child(6) .faq-one__icon span {
  background-color: #33a9bb;
}

.faq-one [class*=col-lg-]:nth-child(6) .faq-one__icon span:before {
  border-color: #33a9bb;
}

.faq-one__single {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.faq-one__single:hover .faq-one__icon span::before {
  left: 0;
}

.faq-one__icon {
  margin-right: 30px;
}

.faq-one__icon span {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 30px;
  color: #fff;
  background-color: #f16101;
  font-family: "Satisfy";
  line-height: 70px;
  position: relative;
}

.faq-one__icon span::before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #f16101;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.faq-one__title {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #012237;
  margin-bottom: 55px;
}

.faq-one__text {
  font-size: 16px;
  line-height: 34px;
  margin: 0;
  color: #808080;
  margin-top: -10px;
  margin-bottom: -10px;
}

/*
* 7. cta styles
*/

.cta-one {
  background-color: #f16101;
  padding-top: 101.5px;
  padding-bottom: 101.5px;
}

.cta-one .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cta-one__title {
  margin: 0;
  font-size: 50px;
  font-weight: bold;
  color: #fff;
  margin-top: -10px;
}

.cta-one__btn {
  font-size: 16px;
  color: #fff;
  background-color: #012237;
  padding: 21.5px 47.5px;
}

.cta-one__home-one {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  position: relative;
  padding-top: 121px;
  padding-bottom: 121px;
}

.cta-one__home-one::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f16101;
  opacity: 0.9;
}

.cta-one__home-one .container {
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.cta-one__home-one .cta-one__title {
  font-size: 60px;
  margin-bottom: 60px;
}

.cta-two .container-fluid {
  padding: 0;
}

.cta-two .thm-base-bg-2 .cta-two__icon span i {
  color: #2da397;
}

.cta-two__single {
  padding-left: 135px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-top: 116px;
  padding-bottom: 116px;
}

.cta-two__icon {
  margin-right: 60px;
}

.cta-two__icon span {
  background-color: #fff;
  width: 156px;
  height: 156px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.cta-two__icon span::before {
  content: '';
  position: absolute;
  top: 0;
  left: -15px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #fff;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.cta-two__icon span:hover:before {
  left: 0;
}

.cta-two__icon span i {
  color: #f16101;
  font-size: 64px;
}

.cta-two__title {
  margin: 0;
  font-size: 50px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 25px;
}

.cta-two__text {
  margin: 0;
  font-size: 16px;
  line-height: 34px;
  color: #fff;
  margin-top: -10px;
  margin-bottom: -5px;
}

.cta-two__btn {
  font-size: 16px;
  color: #fff;
  background-color: #012237;
  padding: 16.5px 47.5px;
  margin-top: 35px;
}

.cta-three .block-title {
  margin-bottom: 45px;
}

.cta-three .block-title__title {
  font-size: 50px;
}

.cta-three__image {
  float: right;
}

.cta-three__content {
  padding: 105px 0;
  padding-left: 70px;
}

.cta-three__text {
  font-size: 16px;
  line-height: 34px;
  color: #808080;
  font-weight: 500;
  margin: 0;
  margin-bottom: 60px;
}

.cta-three i {
  font-size: 64px;
  color: #2da397;
}

.cta-three__single-text {
  font-size: 18px;
  font-weight: 600;
  color: #012237;
  line-height: 1.2em;
  margin: 0;
  margin-top: 20px;
}

.cta-three__single {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 33.33%;
  flex: 1 1 33.33%;
  max-width: 33.333%;
  padding-left: 45px;
  padding-right: 45px;
}

.cta-three__single-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -45px;
  margin-right: -45px;
  margin-top: -10px;
  margin-bottom: 50px;
}

.cta-three .thm-btn {
  padding: 21.5px 68px;
}

.cta-four {
  padding-top: 120px;
  padding-bottom: 472px;
  background-image: url(../images/teacher-1-1.jpg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  position: relative;
}

.cta-four__stripe {
  position: absolute;
  top: -2%;
  left: 10%;
}

.cta-four__line {
  position: absolute;
  top: 3%;
  right: 5%;
}

.cta-four__text {
  font-size: 18px;
  color: #808080;
  line-height: 36px;
  margin: 0;
}

.cta-four .block-title {
  margin-bottom: 35px;
}

.cta-five {
  position: relative;
  background-image: url(../images/cta-map-1-1.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #2da397;
  padding: 120px 0;
}

.cta-five__title {
  margin: 0;
  font-size: 60px;
  font-weight: bold;
  color: #fff;
}

.cta-five__text {
  margin: 0;
  font-size: 18px;
  color: #fff;
  margin-top: 15px;
}

.cta-five__btn {
  padding: 21.5px 68px;
  font-size: 18px;
  margin-top: 50px;
}

[class*=cta-five__members-] {
  width: 71px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  border-radius: 50%;
  -webkit-animation-name: bubbleMoveTwo;
  animation-name: bubbleMoveTwo;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.cta-five__members-1 {
  top: 10%;
  left: 25%;
}

.cta-five__members-2 {
  top: 45%;
  left: 25%;
  -webkit-transform: scale(0.65);
  transform: scale(0.65);
}

.cta-five__members-3 {
  bottom: 10%;
  left: 12%;
}

.cta-five__members-4 {
  top: 15%;
  right: 15%;
}

.cta-five__members-5 {
  top: 45%;
  right: 5%;
  -webkit-transform: scale(0.65);
  transform: scale(0.65);
}

.cta-five__members-6 {
  bottom: 15%;
  right: 25%;
}

[class*=cta-five__bubble-] {
  background-color: #f16101;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.2);
  width: 47px;
  height: 47px;
  position: absolute;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 47px;
}

[class*=cta-five__bubble-] i.fa-rocket {
  font-size: 18px;
}

.cta-five__bubble-1 {
  top: 40%;
  left: 10%;
}

.cta-five__bubble-2 {
  bottom: 30%;
  right: 10%;
}

.cta-six {
  position: relative;
}

.cta-six__line {
  position: absolute;
  left: 10%;
  top: 30%;
}

.cta-six .container-fluid {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

.cta-six__left {
  float: left;
  width: 100%;
  max-width: 56.62%;
}

.cta-six__right {
  float: left;
  width: 100%;
  max-width: 43.38%;
}

.cta-six__content {
  padding: 120px 0;
  margin-left: auto;
  max-width: 580px;
  margin-right: 120px;
}

.cta-six__title {
  padding-top: 41px;
  padding-bottom: 41px;
  padding-left: 80px;
  margin: 0;
  background-color: #012237;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  position: relative;
}

.cta-six__title::before {
  content: '';
  width: 35px;
  height: 10px;
  background-color: #012237;
  position: absolute;
  bottom: 100%;
  left: 80px;
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.cta-six__title span {
  font-size: 40px;
  color: #f16101;
  font-family: "Satisfy";
  font-weight: 400;
}

/*
* 8. team styles
*/

.team-one {
  padding-bottom: 90px;
  padding-top: 120px;
}

.team-one__single {
  margin-bottom: 30px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.team-one__single:hover {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
}

.team-one__single:hover .team-one__image>img {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.team-one__image {
  text-align: center;
  margin-bottom: -103px;
}

.team-one__image img {
  border-radius: 50%;
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.team-one__content {
  text-align: center;
  border: 2px solid #f1f1f1;
  padding-bottom: 30px;
  padding-top: 135px;
  padding-left: 40px;
  padding-right: 40px;
}

.team-one__social {
  background-color: #f1f1f1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 11.5px;
  padding-bottom: 11.5px;
}

.team-one__social a {
  font-size: 14px;
  color: #808080;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.team-one__social a:hover {
  color: #f16101;
}

.team-one__social a+a {
  margin-left: 28px;
}

.team-one__name {
  font-size: 18px;
  font-weight: 600;
  color: #012237;
  margin: 0;
  margin-bottom: -5px;
}

.team-one__name a {
  color: inherit;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.team-one__name a:hover {
  color: #f16101;
}

.team-one__designation {
  font-size: 12px;
  text-transform: uppercase;
  color: #808080;
  letter-spacing: .2em;
  line-height: 1em;
  margin: 0;
  margin-top: 15px;
  margin-bottom: 20px;
}

.team-one__text {
  margin: 0;
  font-size: 14px;
  color: #808080;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: -10px;
}

.team-details {
  padding-top: 120px;
  padding-bottom: 90px;
}

.team-details .team-one__image {
  margin-bottom: 0;
}

.team-details .team-one__image img {
  border-radius: 0;
}

.team-details .team-one__content {
  padding-top: 30px;
}

.team-details .team-one__designation {
  margin-bottom: 0;
}

.team-details__title {
  margin: 0;
  color: #012237;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 30px;
}

.team-details__text {
  margin: 0;
  color: #808080;
  font-size: 16px;
  line-height: 34px;
  font-weight: 500;
}

.team-details__subtitle {
  margin: 0;
  color: #012237;
  font-size: 20px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.team-details__certificate-list {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}

.team-details__certificate-list li+li {
  margin-left: 25px;
}

.progress-one__single+.progress-one__single {
  margin-top: 20px;
}

.progress-one__title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #012237;
  line-height: 1em;
}

.progress-one__percent {
  margin: 0;
  font-size: 20px;
  color: #f16101;
  font-family: "Satisfy";
  line-height: 1em;
}

.progress-one__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}

.progress-one__bar {
  width: 100%;
  height: 5px;
  background-color: #f1f1f1;
  position: relative;
  overflow: hidden;
}

.progress-one__bar span {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #2da397;
  height: 100%;
}

.team-tab {
  position: relative;
  background-color: #022c46;
}

.team-tab:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../images/patter-1-1.jpg);
  opacity: 0.08;
}

.team-tab .container {
  position: relative;
}

.team-tab__content {
  padding-right: 80px;
}

.team-tab__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.team-tab__title {
  margin: 0;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}

.team-tab__designation {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .2em;
  color: #94a2ab;
  margin: 0;
}

.team-tab__text {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 34px;
  color: #94a2ab;
  margin-top: 10px;
  margin-bottom: 20px;
}

.team-tab__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.team-tab__social a {
  font-size: 18px;
  color: #fff;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.team-tab__social a:hover {
  color: #f16101;
}

.team-tab__social a+a {
  margin-left: 38px;
}

.team-tab .progress-one__bar {
  background-color: rgba(255, 255, 255, 0.2);
}

.team-tab .progress-one__percent {
  color: #fff;
}

.team-tab .progress-one__title {
  color: #fff;
}

.team-tab__btn {
  font-size: 18px;
  padding: 21.5px 47.5px;
  margin-top: 40px;
}

.team-tab__tab-navs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 100px;
  right: 20%;
  border: none;
}

.team-tab__tab-navs img {
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 50%;
  background-color: #012237;
  -webkit-box-shadow: 5px 8.66px 30px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 8.66px 30px 0px rgba(0, 0, 0, 0.2);
  width: 82px;
  opacity: 0.4;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.team-tab__tab-navs .active img {
  opacity: 1;
}

.team-tab__tab-navs li+li {
  margin-left: 10px;
}

/*
* 9. pricing styles
*/

.pricing-one {
  background-color: #f1f1f1;
  padding-top: 120px;
  padding-bottom: 90px;
}

.pricing-one [class*=col-lg-]:nth-child(1) .pricing-one__tag-line, .pricing-one [class*=col-lg-]:nth-child(1) .pricing-one__price {
  color: #2da397;
}

.pricing-one [class*=col-lg-]:nth-child(1) .pricing-one__single:after {
  border-color: #2da397;
}

.pricing-one [class*=col-lg-]:nth-child(2) .pricing-one__tag-line, .pricing-one [class*=col-lg-]:nth-child(2) .pricing-one__price {
  color: #f16101;
}

.pricing-one [class*=col-lg-]:nth-child(2) .pricing-one__single:after {
  border-color: #f16101;
}

.pricing-one [class*=col-lg-]:nth-child(3) .pricing-one__tag-line, .pricing-one [class*=col-lg-]:nth-child(3) .pricing-one__price {
  color: #7c4bc0;
}

.pricing-one [class*=col-lg-]:nth-child(3) .pricing-one__single:after {
  border-color: #7c4bc0;
}

.pricing-one__single {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 60px;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 60px;
}

.pricing-one__single::before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - 20px);
  height: 100%;
  background-color: #fff;
  z-index: 10;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.pricing-one__single::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 20px);
  height: 100%;
  border: 2px solid #2da397;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.pricing-one__single:hover:before {
  right: 20px;
}

.pricing-one__single:hover::after {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
}

.pricing-one__inner {
  position: relative;
  z-index: 11;
}

.pricing-one__price {
  font-size: 60px;
  font-weight: 400;
  margin: 0;
  line-height: 1em;
  color: #2da397;
  margin-bottom: -15px;
}

.pricing-one__name {
  font-size: 14px;
  font-weight: 600;
  color: #012237;
  text-transform: uppercase;
  letter-spacing: .2em;
  margin: 0;
  line-height: 1em;
  margin-top: 30px;
  margin-bottom: 40px;
}

.pricing-one__list {
  margin: 0;
  border-top: 1px solid #f1f1f1;
  padding-top: 40px;
  margin-bottom: 35px;
}

.pricing-one__list li {
  color: #808080;
  font-size: 16px;
  line-height: 1em;
}

.pricing-one__list li+li {
  margin-top: 30px;
}

.pricing-one__btn {
  background-color: #f1f1f1;
  color: #808080;
  font-size: 16px;
  padding: 16.5px 48px;
  margin-bottom: 40px;
}

.pricing-one__btn:hover {
  background-color: #f16101;
  color: #fff;
}

.pricing-one__tag-line {
  font-size: 12px;
  color: #2da397;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .2em;
  line-height: 1em;
  margin: 0;
}

/*
* 10. become-teacher styles
*/

.become-teacher {
  padding-top: 120px;
}

.become-teacher__form {
  padding-left: 70px;
  position: relative;
}

.become-teacher__form:before {
  content: '';
  width: 197px;
  height: 268px;
  background-image: url(../images/dot-pattern-1-1.png);
  position: absolute;
  bottom: -11%;
  left: 87%;
  z-index: -1;
}

.become-teacher__form-top {
  background-color: #2da397;
  padding-left: 60px;
  position: relative;
}

.become-teacher__form-top::before {
  content: '';
  width: 38px;
  height: 10px;
  background-image: url(../images/become-form-angle.png);
  background-position: 0 0;
  position: absolute;
  top: 100%;
  left: 60px;
}

.become-teacher__form-title {
  margin: 0;
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  padding: 29px 0;
}

.become-teacher__form-content {
  border-style: solid;
  border-width: 2px;
  border-color: #f1f1f1;
  background-color: white;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 60px;
  padding-bottom: 60px;
}

.become-teacher__form input {
  border: none;
  outline: none;
  width: 100%;
  background-color: #f1f1f1;
  height: 77px;
  font-size: 16px;
  color: #808080;
  font-weight: 500;
  padding-left: 40px;
  display: block;
  margin-bottom: 10px;
}

.become-teacher__form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #808080;
}

.become-teacher__form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #808080;
}

.become-teacher__form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #808080;
}

.become-teacher__form input:-moz-placeholder {
  /* Firefox 18- */
  color: #808080;
}

.become-teacher__form label {
  display: block;
}

.become-teacher__form-btn {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  padding: 21.5px 58px;
}

.become-teacher__form-btn:hover {
  background-color: #012237;
  color: #fff;
}

.become-teacher__title {
  margin: 0;
  color: #012237;
  font-size: 40px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 40px;
}

.become-teacher__subtitle {
  font-size: 20px;
  color: #012237;
  font-weight: 600;
  margin: 0;
  margin-top: 40px;
  margin-bottom: 20px;
}

.become-teacher__text {
  font-size: 16px;
  line-height: 34px;
  font-weight: 500;
  color: #808080;
  margin: 0;
}

/*
* 11. course styles
*/

.course-one {
  /* padding-top: 120px; */
  padding-bottom: 120px;
}

.course-one .post-pagination {
  margin-top: 30px;
}

.course-one__carousel .color-1 .course-one__category {
  background-color: #7c4bc0;
}

.course-one__carousel .color-2 .course-one__category {
  background-color: #1888c9;
}

.course-one__carousel .color-3 .course-one__category {
  background-color: #d23f3f;
}

.course-one__carousel .color-4 .course-one__category {
  background-color: #2da397;
}

.course-one__carousel .color-5 .course-one__category {
  background-color: #d23f3f;
}

.course-one__carousel .color-6 .course-one__category {
  background-color: #33a9bb;
}

.course-one [class*=col-lg-]:nth-child(1) .course-one__category {
  background-color: #7c4bc0;
}

.course-one [class*=col-lg-]:nth-child(2) .course-one__category {
  background-color: #1888c9;
}

.course-one [class*=col-lg-]:nth-child(3) .course-one__category {
  background-color: #d23f3f;
}

.course-one [class*=col-lg-]:nth-child(4) .course-one__category {
  background-color: #2da397;
}

.course-one [class*=col-lg-]:nth-child(5) .course-one__category {
  background-color: #d23f3f;
}

.course-one [class*=col-lg-]:nth-child(6) .course-one__category {
  background-color: #33a9bb;
}

.course-one__single {
  margin-bottom: 30px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.course-one__single:hover {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
}

.course-one__image {
  position: relative;
  border-style: solid;
  border-width: 2px;
  border-color: #808080;
  border-bottom-width: 0;
}

.course-one__image>img {
  width: 100%;
}

.course-one__image i {
  font-size: 16px;
  color: #fff;
  position: absolute;
  top: 30px;
  right: 30px;
}

.course-one__category {
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  letter-spacing: .2em;
  background-color: #f16101;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 27px;
  padding: 0 17px;
  position: absolute;
  left: 50px;
  top: -13.5px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.course-one__category:hover {
  color: #fff;
  background-color: #012237;
}

.course-one__content {
  padding-left: 45px;
  padding-right: 45px;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  border-style: solid;
  border-width: 2px;
  border-color: #808080;
  border-top: 0;
  background-color: #fff;
}

.course-one__admin {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #808080;
  margin: 0;
  line-height: 1em;
  margin-bottom: 20px;
}

.course-one__admin>img {
  width: 21px !important;
  border-radius: 50%;
  margin-right: 10px;
}

.course-one__admin a {
  color: #2da397;
  margin-left: 5px;
}

.course-one__image>img {
  width: 100%;
}

.course-one__stars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.course-one__stars-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.course-one__stars-wrap i {
  font-size: 14px;
  color: #f16101;
}

.course-one__title {
  font-size: 24px;
  font-weight: 600;
  color: #466895;
  margin: 0;
  margin-bottom: 5px;
}

.course-one__title a {
  color: inherit;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.course-one__title a:hover {
  color: #f16101;
}

.course-one__count {
  font-size: 14px;
  font-weight: 500;
  color: #012237;
  margin-left: 10px;
}

.course-one__stars-count {
  font-size: 11px;
  color: #808080;
  font-weight: 500;
  background-color: #f1f1f1;
  display: inline-block;
  vertical-align: middle;
  line-height: 22px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
}

.course-one__meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 2px solid #f1f1f1;
  padding-top: 20px;
  margin-top: 15px;
  font-size: 0.9em;
  color: #466895;
  font-weight: bolder;
}

.course-one__meta a {
  color: #808080;
  font-size: 14px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1em;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.course-one__meta a:hover {
  color: #2da397;
}

.course-one__meta a:hover i {
  color: #012237;
}

.course-one__meta-description {
  height: 120px;
  font-weight: 200;
  padding-top: 10px;
}

.course-one__meta a:last-child {
  color: #012237;
  font-weight: 600;
}

.course-one__meta i {
  font-size: 14px;
  color: #f16101;
  margin-right: 10px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.course-one__link {
  background-color: #f1f1f1;
  width: 100%;
  color: #808080;
  font-size: 16px;
  font-weight: 600;
  display: block;
  text-align: center;
  padding: 11.5px 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  margin-top: 20px;
}

.course-one__link:hover {
  background-color: #f16101;
  color: #fff;
}

.course-one__teacher-details {
  padding-bottom: 90px;
}

.course-one__top-title {
  padding: 128px 0;
  position: relative;
}

.course-one__top-title::before {
  content: '';
  width: 145px;
  height: 60px;
  background-color: #f1f1f1;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.course-details {
  padding: 120px 0;
}

.course-details .list-unstyled {
  margin-bottom: 0;
}

.course-details .course-one__category {
  position: relative;
  top: auto;
  left: auto;
  -webkit-transform: translate(0);
  transform: translate(0);
}

.course-details__author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #808080;
  font-size: 18px;
  font-weight: 500;
}

.course-details__author a {
  color: #2da397;
  margin-left: 10px;
}

.course-details__author img {
  width: 53px;
  border-radius: 50%;
  margin-right: 20px;
}

.course-details__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.course-details__title {
  font-size: 40px;
  font-weight: 600;
  color: #012237;
  margin: 0;
  margin-bottom: 5px;
}

.course-details__progress-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.course-details__progress-text {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  min-width: 100px;
}

.course-details__progress-count {
  margin: 0;
  font-family: "Satisfy";
}

.course-details__progress-bar {
  position: relative;
  width: 210px;
  height: 2px;
  background-color: #f1f1f1;
  margin-left: 20px;
  margin-right: 20px;
}

.course-details__progress-bar span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #f16101;
}

.course-details__progress-bar span.no-bubble:before {
  display: none;
}

.course-details__progress-bar span:before {
  content: '';
  width: 15px;
  height: 15px;
  background-color: #f16101;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.course-details__review-box {
  width: 237px;
  height: 237px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #f1f1f1;
}

.course-details__review-count {
  margin: 0;
  line-height: 1em;
  font-size: 60px;
  color: #2da397;
  margin-bottom: 5px;
}

.course-details__review-stars {
  font-size: 24px;
  line-height: 1em;
  margin-bottom: 15px;
}

.course-details__review-stars i {
  color: #f16101;
}

.course-details__review-text {
  margin: 0;
  line-height: 1em;
  font-size: 12px;
  color: #808080;
  letter-spacing: .2em;
}

.course-details__comment {
  margin-left: -50px;
  margin-right: -50px;
  border-top: 2px solid #f1f1f1;
  border-bottom: 2px solid #f1f1f1;
  padding-top: 50px;
  padding-bottom: 40px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 40px;
  margin-top: 50px;
}

.course-details__comment-top, .course-details__comment-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.course-details__comment-single+.course-details__comment-single {
  padding-top: 50px;
  margin-top: 40px;
  border-top: 2px solid #f1f1f1;
}

.course-details__comment-meta {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.course-details__comment-img {
  width: 76px;
  margin-right: 20px;
}

.course-details__comment-img>img {
  width: 100%;
}

.course-details__comment-name {
  font-size: 20px;
  font-weight: 600;
  color: #012237;
  margin: 0;
}

.course-details__comment-date {
  font-size: 16px;
  color: #2da397;
  margin: 0;
  font-weight: 500;
  margin-right: 20px;
}

.course-details__comment-stars {
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.course-details__comment-stars i {
  color: #f16101;
}

.course-details__comment-stars i.star-disabled {
  color: #f1f1f1;
}

.course-details__comment-text {
  font-size: 16px;
  line-height: 34px;
  font-weight: 500;
  color: #808080;
  margin: 0;
  margin-top: 20px;
}

.course-details__comment-form-text {
  font-size: 18px;
  color: #808080;
  font-weight: 500;
  margin: 0;
  margin-bottom: 20px;
}

.course-details__comment-form-text a {
  font-size: 20px;
  color: #f1f1f1;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.course-details__comment-form-text a:focus, .course-details__comment-form-text a:hover {
  color: #f16101;
  cursor: pointer;
}

.course-details__comment-form input, .course-details__comment-form textarea {
  display: block;
  width: 100%;
  height: 77px;
  background-color: #f1f1f1;
  color: #808080;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  padding-left: 40px;
  margin-bottom: 10px;
}

.course-details__comment-form input::-webkit-input-placeholder, .course-details__comment-form textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #808080;
}

.course-details__comment-form input::-moz-placeholder, .course-details__comment-form textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #808080;
}

.course-details__comment-form input:-ms-input-placeholder, .course-details__comment-form textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #808080;
}

.course-details__comment-form input:-moz-placeholder, .course-details__comment-form textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #808080;
}

.course-details__comment-form textarea {
  height: 210px;
}

.course-details__comment-form-btn {
  font-size: 18px;
  padding: 21.5px 58px;
}

.course-details__comment-form-btn:hover {
  background-color: #012237;
  color: #fff;
}

.course-details__tab-navs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #f1f1f1;
  margin-top: 30px;
  margin-bottom: 0;
}

.course-details__tab-navs li {
  line-height: 1em;
}

.course-details__tab-navs li a {
  color: #808080;
  font-size: 18px;
  font-weight: 500;
  display: block;
  padding: 30.5px 50px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  position: relative;
}

.course-details__tab-navs li a::before {
  content: '';
  width: 35px;
  height: 10px;
  background-color: #2da397;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%) scaleY(0);
  transform: translateX(-50%) scaleY(0);
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

.course-details__tab-navs li a:hover, .course-details__tab-navs li a.active {
  background-color: #2da397;
  color: #fff;
}

.course-details__tab-navs li a:hover:before, .course-details__tab-navs li a.active:before {
  -webkit-transform: translateX(-50%) scaleY(1);
  transform: translateX(-50%) scaleY(1);
}

.course-details__tab-content {
  border-style: solid;
  border-width: 2px;
  border-color: #f1f1f1;
  padding: 50px;
}

.course-details__tab-content .course-details__meta-icon {
  width: 30px;
  height: 30px;
}

.course-details__tab-content .course-details__meta-icon i {
  font-size: 12px;
}

.course-details__tab-content .course-details__meta-icon:before {
  border-width: 1px;
}

.course-details__tab-content .course-details__meta-icon.video-icon {
  background-color: #7c4bc0;
}

.course-details__tab-content .course-details__meta-icon.video-icon:before {
  border-color: #7c4bc0;
}

.course-details__tab-content .course-details__meta-icon.file-icon {
  background-color: #f16101;
}

.course-details__tab-content .course-details__meta-icon.file-icon:before {
  border-color: #f16101;
}

.course-details__tab-content .course-details__meta-icon.quiz-icon {
  background-color: #d23f3f;
}

.course-details__tab-content .course-details__meta-icon.quiz-icon:before {
  border-color: #d23f3f;
}

.course-details__tab-text {
  margin: 0;
  font-size: 16px;
  line-height: 34px;
  font-weight: 500;
  color: #808080;
}

.course-details__tab-title {
  font-size: 20px;
  color: #012237;
  font-weight: 600;
  margin: 0;
}

.course-details__overview-list li {
  color: #f16101;
  font-size: 16px;
  font-weight: 500;
}

.course-details__curriculum-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 22.5px;
  padding-bottom: 22.5px;
  border-bottom: 1px solid #f1f1f1;
}

.course-details__curriculum-list li:first-child {
  padding-top: 0;
}

.course-details__curriculum-list-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.course-details__curriculum-list-left a {
  font-size: 16px;
  font-weight: 600;
  color: #012237;
}

.course-details__curriculum-list-left span {
  font-size: 12px;
  color: #808080;
  font-weight: 500;
  background-color: #f1f1f1;
  display: inline-block;
  vertical-align: middle;
  line-height: 22px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 15px;
}

.course-details__curriculum-list-right {
  font-size: 16px;
  font-weight: 500;
  color: #808080;
}

.course-details__price {
  background-color: #f1f1f1;
  text-align: center;
  padding: 50px;
}

.course-details__price-text {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: #808080;
  text-transform: uppercase;
  letter-spacing: .2em;
  line-height: 1em;
}

.course-details__price-amount {
  margin: 0;
  color: #012237;
  font-size: 30px;
  font-weight: 600;
  line-height: 1em;
  margin-top: 10px;
}

.course-details__price-btn {
  padding-top: 11.5px;
  padding-bottom: 11.5px;
  width: 100%;
  margin-top: 30px;
}

.course-details__meta {
  margin-top: 30px;
  margin-bottom: 30px;
  border-style: solid;
  border-width: 2px;
  border-color: #f1f1f1;
}

.course-details__meta-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #808080;
  font-size: 18px;
  font-weight: 500;
  padding: 25px 50px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.course-details__meta-link:hover {
  color: #f16101;
}

.course-details__meta-link:hover .course-details__meta-icon::before {
  left: 0;
}

.course-details__meta-link span {
  color: #012237;
  margin-left: 15px;
}

.course-details__meta-link:nth-child(1) .course-details__meta-icon {
  background-color: #1888c9;
}

.course-details__meta-link:nth-child(1) .course-details__meta-icon::before {
  border-color: #1888c9;
}

.course-details__meta-link:nth-child(2) .course-details__meta-icon {
  background-color: #7c4bc0;
}

.course-details__meta-link:nth-child(2) .course-details__meta-icon::before {
  border-color: #7c4bc0;
}

.course-details__meta-link:nth-child(3) .course-details__meta-icon {
  background-color: #f16101;
}

.course-details__meta-link:nth-child(3) .course-details__meta-icon::before {
  border-color: #f16101;
}

.course-details__meta-link:nth-child(4) .course-details__meta-icon {
  background-color: #2da397;
}

.course-details__meta-link:nth-child(4) .course-details__meta-icon::before {
  border-color: #2da397;
}

.course-details__meta-link:nth-child(5) .course-details__meta-icon {
  background-color: #d23f3f;
}

.course-details__meta-link:nth-child(5) .course-details__meta-icon::before {
  border-color: #d23f3f;
}

.course-details__meta-link:nth-child(6) .course-details__meta-icon {
  background-color: #33a9bb;
}

.course-details__meta-link:nth-child(6) .course-details__meta-icon::before {
  border-color: #33a9bb;
}

.course-details__meta-link+.course-details__meta-link {
  border-top: 2px solid #f1f1f1;
}

.course-details__meta-icon {
  width: 40px;
  height: 40px;
  background-color: #2da397;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 20px;
  position: relative;
}

.course-details__meta-icon:before {
  content: '';
  position: absolute;
  left: -5px;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid;
  border-color: #2da397;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.course-details__meta-icon i {
  color: #fff;
  font-size: 16px;
}

.course-details__list {
  border-style: solid;
  border-width: 2px;
  border-color: #f1f1f1;
  padding: 50px;
}

.course-details__list-title {
  margin: 0;
  color: #012237;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}

.course-details__list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.course-details__list-item+.course-details__list-item {
  border-top: 2px solid #f1f1f1;
  padding-top: 30px;
  margin-top: 30px;
}

.course-details__list-img {
  width: 67px;
  margin-right: 30px;
}

.course-details__list-img>img {
  width: 100%;
}

.course-details__list-author {
  font-size: 14px;
  color: #808080;
  line-height: 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.course-details__list-author span {
  color: #2da397;
  margin-left: 6px;
}

.course-details__list h3 {
  font-size: 16px;
  font-weight: 600;
  color: #808080;
  margin: 0;
  line-height: 1em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.course-details__list h3 a {
  color: inherit;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.course-details__list h3 a:hover {
  color: #012237;
}

.course-details__list-stars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.course-details__list-stars i {
  font-size: 14px;
  color: #f16101;
}

.course-details__list-stars span {
  font-size: 14px;
  font-weight: 500;
  color: #012237;
  line-height: 1em;
  margin-left: 10px;
}

.course-one__carousel .owl-dots .owl-dot {
  outline: none;
}

.course-one__carousel .owl-nav.disabled+.owl-dots {
  margin-top: 30px;
}

.course-one__carousel .owl-dots .owl-dot:hover span, .course-one__carousel .owl-dots .owl-dot.active span {
  -webkit-transform: scale(1.56);
  transform: scale(1.56);
  opacity: 1;
  background-color: #012237;
}

.course-one__carousel .owl-dots .owl-dot span {
  width: 9px;
  height: 9px;
  background-color: #012237;
  margin: 0 2.5px;
  border-radius: 50%;
  opacity: 0.2;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

.course-one__top-title.home-one {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #466895;
  padding-bottom: 188px;
}

.course-one__top-title.home-one:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../images/Artboard 11.png');
  opacity: 0.8;
}

.course-one__top-title.home-one .block-title__title {
  color: #fff;
  z-index: 9;
  position: relative;
}

.course-one__top-title.home-one:before {
  display: none;
}

.course-one__top-title__curve {
  width: 100%;
  height: 60px;
  background-image: url(../images/course-title-curve.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.course-category-one {
  padding: 120px 0;
  padding-top: 110px;
}

.course-category-one .container-fluid {
  max-width: 1520px;
  width: 100%;
}

.course-category-one__single {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
  text-align: center;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  margin-bottom: 60px;
}

.course-category-one__single:hover {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
}

.course-category-one__single.color-1 .course-category-one__icon {
  background-color: #1888c9;
}

.course-category-one__single.color-1 .course-category-one__icon:before {
  border-color: #1888c9;
}

.course-category-one__single.color-2 .course-category-one__icon {
  background-color: #7c4bc0;
}

.course-category-one__single.color-2 .course-category-one__icon:before {
  border-color: #7c4bc0;
}

.course-category-one__single.color-3 .course-category-one__icon {
  background-color: #f16101;
}

.course-category-one__single.color-3 .course-category-one__icon:before {
  border-color: #f16101;
}

.course-category-one__single.color-4 .course-category-one__icon {
  background-color: #2da397;
}

.course-category-one__single.color-4 .course-category-one__icon:before {
  border-color: #2da397;
}

.course-category-one__single.color-5 .course-category-one__icon {
  background-color: #d23f3f;
}

.course-category-one__single.color-5 .course-category-one__icon:before {
  border-color: #d23f3f;
}

.course-category-one__single.color-6 .course-category-one__icon {
  background-color: #33a9bb;
}

.course-category-one__single.color-6 .course-category-one__icon:before {
  border-color: #33a9bb;
}

.course-category-one__icon {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  background-color: #f16101;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.course-category-one__icon:before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  width: 100%;
  height: 100%;
  border: 2px solid #f16101;
  border-radius: 50%;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.course-category-one__icon i {
  font-size: 56px;
  line-height: 112px;
  color: #fff;
}

.course-category-one__icon:hover:before {
  left: 0;
}

.course-category-one__title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #012237;
  margin-top: 40px;
}

.course-category-one__title a {
  color: inherit;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.course-category-one__title a:hover {
  color: #f16101;
}

.course-category-one .thm-btn {
  padding: 21.5px 68px;
  background-color: #012237;
  color: #fff;
}

.course-category-one .thm-btn:hover {
  background-color: #f16101;
  color: #fff;
}

.course-category-two .inner-container {
  padding: 75px 40px;
  padding-bottom: 0;
  background-color: #fff;
  margin-top: -120px;
  position: relative;
  z-index: 10;
}

.course-category-two .owl-dots .owl-dot {
  outline: none;
}

.course-category-two .owl-nav.disabled+.owl-dots {
  margin-top: 30px;
}

.course-category-two .owl-dots .owl-dot:hover span, .course-category-two .owl-dots .owl-dot.active span {
  -webkit-transform: scale(1.56);
  transform: scale(1.56);
  opacity: 1;
  background-color: #012237;
}

.course-category-two .owl-dots .owl-dot span {
  width: 9px;
  height: 9px;
  background-color: #012237;
  margin: 0 2.5px;
  border-radius: 50%;
  opacity: 0.2;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

.course-category-two__single {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  position: relative;
}

.course-category-two__single:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0px;
  width: calc(100% - 10px);
  height: 100%;
  border: 2px solid #f16101;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.course-category-two__single:after {
  content: '';
  position: absolute;
  top: 0;
  left: 10px;
  width: calc(100% - 10px);
  height: 100%;
  background-color: #f16101;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.course-category-two__single:hover {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
}

.course-category-two__single:hover:before {
  left: 10px;
}

.course-category-two__single.color-1:after {
  background-color: #1888c9;
}

.course-category-two__single.color-1:before {
  border-color: #1888c9;
}

.course-category-two__single.color-2:after {
  background-color: #7c4bc0;
}

.course-category-two__single.color-2:before {
  border-color: #7c4bc0;
}

.course-category-two__single.color-3:after {
  background-color: #f16101;
}

.course-category-two__single.color-3:before {
  border-color: #f16101;
}

.course-category-two__single.color-4:after {
  background-color: #2da397;
}

.course-category-two__single.color-4:before {
  border-color: #2da397;
}

.course-category-two__single.color-5:after {
  background-color: #d23f3f;
}

.course-category-two__single.color-5:before {
  border-color: #d23f3f;
}

.course-category-two__single.color-6:after {
  background-color: #33a9bb;
}

.course-category-two__single.color-6:before {
  border-color: #33a9bb;
}

.course-category-two__icon {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 10;
}

.course-category-two__icon i {
  font-size: 56px;
  line-height: 112px;
  color: #fff;
}

.course-category-two__icon:hover:before {
  left: 0;
}

.course-category-two__title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-top: 25px;
  position: relative;
  z-index: 10;
}

.course-category-two__title a {
  color: inherit;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.course-category-two__title a:hover {
  color: #012237;
}

.course-one__top-title.home-two {
  padding-top: 120px;
  padding-bottom: 0;
}

.course-one__top-title.home-two:before {
  display: none;
}

.course-one.home-two {
  padding-top: 0;
  position: relative;
  background-color: #022c46;
  margin-top: 320px;
}

.course-one.home-two:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../images/patter-1-1.jpg);
  opacity: 0.08;
}

.course-one.home-two .course-one__carousel {
  bottom: 243px;
  margin-bottom: -245px;
}

.course-one.home-two .course-one__carousel .owl-dots .owl-dot span {
  background-color: #fff;
  opacity: 0.2;
}

.course-one.home-two .course-one__carousel .owl-dots .owl-dot.active span {
  background-color: #fff;
  opacity: 1;
}

.course-one.home-two .course-one__line {
  position: absolute;
  bottom: 100%;
  left: 4%;
}

.course-one__top-title.home-three {
  padding-top: 120px;
  padding-bottom: 0;
}

.course-one__top-title.home-three:before {
  display: none;
}

.course-one.home-three {
  padding-top: 0;
  position: relative;
}

.course-one.home-three .course-one__line {
  display: none;
}

.course-category-three {
  position: relative;
  padding: 120px 0;
}

.course-category-three__circle {
  position: absolute;
  bottom: 10%;
  left: 10%;
}

.course-category-three__more-link {
  margin-top: 30px;
  padding: 21.5px 68px;
  background-color: #012237;
  color: #fff;
}

.course-category-three__more-link:hover {
  background-color: #f16101;
  color: #fff;
}

.course-category-three__single {
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  margin-bottom: 30px;
}

.course-category-three__single:hover {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

.course-category-three__single:hover .course-category-three__content {
  opacity: 1;
}

.course-category-three__content {
  width: calc(100% - 40px);
  background-color: #fff;
  position: absolute;
  top: 20px;
  left: 20px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  opacity: 0.8;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.course-category-three__content::before {
  content: '';
  width: 30px;
  height: 10px;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.course-category-three__title {
  font-size: 16px;
  font-weight: 600;
  color: #012237;
  margin: 0;
  line-height: 1em;
  margin-bottom: 8px;
}

.course-category-three__title a {
  color: inherit;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.course-category-three__title a:hover {
  color: #2da397;
}

.course-category-three__text {
  margin: 0;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  color: #808080;
  line-height: 1em;
  letter-spacing: .2em;
}

/*
* 12. inner-banner styles
*/

.inner-banner {
  background-color: #011928;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  background-image: url(../images/inner-bg-1-1.jpg);
}

.inner-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #011928;
  opacity: 0.5;
}

.inner-banner .container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 169px;
}

.inner-banner__title {
  margin: 0;
  text-transform: uppercase;
  font-size: 100px;
  font-weight: 700;
  letter-spacing: -0.04em;
  color: #fff;
  margin-top: 5px;
  margin-bottom: -25px;
}

.thm-breadcrumb {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.thm-breadcrumb li {
  font-size: 20px;
  text-transform: capitalize;
}

.thm-breadcrumb li a {
  color: #fff;
}

.thm-breadcrumb li+li::before {
  content: '.';
  color: #fff;
  margin-left: 15px;
  margin-right: 15px;
}

/*
* 13. footer styles
*/

.site-footer {
  background-color: #286ec946;
}

.site-footer__bottom {
  background-color: #286ec9aa;
}

.site-footer__bottom .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.site-footer__copy {
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.site-footer__copy a {
  color: #ffd786;
  margin-left: 10px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.site-footer__copy a:hover {
  color: #fff;
}

.site-footer__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 35px 0;
  padding-right: 70px;
  padding-left: 30px;
  position: relative;
}

.site-footer__social::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100000px;
  height: 100%;
  background-color: #286ec9aa;
}

.site-footer__social a {
  color: #ffd786;
  font-size: 20px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  position: relative;
  z-index: 10;
}

.site-footer__social a:hover {
  color: #f16101;
}

.site-footer__social a+a {
  margin-left: 40px;
}

.site-footer__scroll-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 60px;
  height: 60px;
  background-color: #466895;
  border-radius: 50%;
  position: absolute !important;
  top: 0;
  left: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.site-footer__scroll-top i {
  font-size: 22px;
  color: #fff;
}

.site-footer__scroll-top::before {
  content: '';
  position: absolute;
  top: 0;
  left: -5px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* border: 2px solid #fff; */
  color: #466895;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.site-footer__scroll-top:hover {
  color: #f16101;
}

.site-footer__scroll-top:hover:before {
  left: 0;
}

.footer-widget {
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer-widget__link-list a {
  color: #466895;
  font-size: 18px;
  line-height: 48px;
}

.footer-widget .list-unstyled {
  margin: 0;
}

.footer-widget__text {
  margin: 0;
  font-size: 15px;
  line-height: 30px;
  color: #94a3ac;
  margin-top: -10px;
}

.footer-widget__about {
  position: relative;
}

.footer-widget__about::before {
  content: '';
  width: 100000px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -60px;
  background-color: #012237;
}

.footer-widget__about * {
  position: relative;
}

.footer-widget__title {
  margin: 0;
  color: #466895;
  font-size: 18px;
  font-weight: normal;
  line-height: 1em;
  margin-bottom: 48px;
}

.footer-widget__link-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer-widget__gallery {
  position: relative;
  left: -35px;
  padding-right: 52px;
}

.footer-widget__gallery-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-left: -5px !important;
  margin-right: -5px !important;
  margin-top: -10px;
}

.footer-widget__gallery-list li {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 33.33333%;
  flex: 1 1 33.33333%;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
}

.footer-widget__gallery-list li a {
  display: block;
}

.footer-widget__gallery-list li a img {
  width: 100%;
}

.footer-widget__btn-block {
  margin-top: 30px;
}

.footer-widget__btn-block .thm-btn:nth-child(2) {
  background-color: #2da397;
  margin-left: 10px;
}

.footer-widget__btn-block .thm-btn:nth-child(2):hover {
  background-color: #fff;
  color: #012237;
}

.footer-widget__link {
  padding-right: 60px;
  position: relative;
  left: -20px;
}

.footer-widget__link-list li {
  line-height: 1em;
}

.footer-widget__link-list li+li {
  margin-top: 20px;
}

.footer-widget__link-list li a {
  color: #466895;
  font-size: 15px;
  font-weight: 500;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.footer-widget__link-list li a:hover {
  color: #f16101;
}

.footer-widget__course-list li+li {
  margin-top: 38px;
}

.footer-widget__course-list li h2 {
  margin: 0;
  font-size: 15px;
  color: #94a3ac;
  font-weight: 500;
  margin-bottom: 10px;
}

.footer-widget__course-list li h2 a {
  color: inherit;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.footer-widget__course-list li p {
  margin: 0;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
}

/*
* 14. blog styles
*/

.blog-one {
  padding-top: 120px;
  padding-bottom: 120px;
}

.blog-one__single {
  margin-bottom: 30px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.blog-one__single:hover {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
}

.blog-one__single:hover .blog-one__image>img {
  opacity: 0.6;
}

.blog-one__single:hover .blog-one__plus {
  opacity: 1;
  -webkit-transform: perspective(20px) translateZ(0px) translate(-50%, -50%);
  transform: perspective(20px) translateZ(0px) translate(-50%, -50%);
}

.blog-one__plus {
  font-size: 46px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: perspective(20px) translateZ(10px) translate(-50%, -50%);
  transform: perspective(20px) translateZ(10px) translate(-50%, -50%);
  -webkit-transition: opacity .4s ease, color .3s ease, -webkit-transform .3s ease;
  transition: opacity .4s ease, color .3s ease, -webkit-transform .3s ease;
  transition: opacity .4s ease, transform .3s ease, color .3s ease;
  transition: opacity .4s ease, transform .3s ease, color .3s ease, -webkit-transform .3s ease;
}

.blog-one__plus:hover {
  color: #f16101;
}

.blog-one__image {
  background-color: #012237;
  position: relative;
  overflow: hidden;
}

.blog-one__image>img {
  width: 100%;
  opacity: 1;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.blog-one__content {
  padding: 0 42px;
  padding-top: 50px;
  padding-bottom: 40px;
  border-style: solid;
  border-width: 2px;
  border-color: #f1f1f1;
  background-color: white;
}

.blog-one__meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
}

.blog-one__meta a {
  width: 40px;
  height: 40px;
  background-color: #f1f1f1;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.blog-one__meta a:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #f1f1f1;
  left: -5px;
  top: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  z-index: 9;
}

.blog-one__meta a:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #f1f1f1;
  left: 0px;
  top: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  z-index: 10;
}

.blog-one__meta a+a {
  margin-left: 15px;
}

.blog-one__meta a:hover {
  background-color: #f16101;
  color: #fff;
}

.blog-one__meta a:hover i {
  color: #fff;
}

.blog-one__meta a:hover:before {
  left: 0;
  border-color: #f16101;
}

.blog-one__meta a:hover:after {
  background-color: #f16101;
}

.blog-one__meta a i {
  font-size: 16px;
  color: #808080;
  position: relative;
  z-index: 11;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.blog-one__title {
  font-size: 30px;
  font-weight: bold;
  color: #012237;
  margin-bottom: 38px;
}

.blog-one__title a {
  color: inherit;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.blog-one__title a:hover {
  color: #f16101;
}

.blog-one__text {
  font-size: 16px;
  line-height: 34px;
  color: #808080;
  font-weight: 500;
  margin: 0;
  margin-bottom: 30px;
}

.blog-one__link {
  font-size: 12px;
  color: #012237;
  text-transform: uppercase;
  letter-spacing: .2em;
  font-weight: bold;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  line-height: 1em;
}

.blog-one__link:hover {
  color: #f16101;
}

.blog-one .post-pagination {
  margin-top: 30px;
}

.blog-one__home-two {
  padding-bottom: 90px;
}

.blog-details {
  padding: 120px 0;
}

.blog-details .blog-one__single {
  margin-bottom: 0;
}

.blog-details .blog-one__title {
  font-size: 50px;
  margin-bottom: 28px;
}

.blog-details .blog-one__text {
  margin-bottom: 0;
}

.blog-details .blog-one__text+.blog-one__text {
  margin-top: 30px;
}

.blog-details__content-title {
  margin: 0;
  color: #012237;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 40px;
}

.blog-details .share-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 50px;
  padding-right: 50px;
  border: 2px solid #f1f1f1;
  border-top: 0;
}

.blog-details .share-block .left-block p {
  margin: 0;
  color: #012237;
  font-size: 16px;
  font-weight: 600;
}

.blog-details .share-block .left-block p a {
  color: #f16101;
  font-size: 16px;
  font-weight: 500;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  margin-left: 5px;
  letter-spacing: 0;
}

.blog-details .share-block .left-block p a:hover {
  color: #012237;
}

.blog-details .share-block .social-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.blog-details .share-block .social-block a {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color: #808080;
  font-size: 18px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.blog-details .share-block .social-block a+a {
  margin-left: 38px;
}

.blog-details .share-block .social-block a:hover {
  color: #f16101;
}

.blog-details__author {
  border: 2px solid #f1f1f1;
  padding: 60px 60px;
  margin-top: 50px;
  margin-bottom: 60px;
}

.blog-details__author-image, .blog-details__author-content {
  display: table-cell;
  vertical-align: top;
}

.blog-details__author-image {
  width: 169px;
}

.blog-details__author-image>img {
  width: 100%;
}

.blog-details__author-content {
  padding-left: 40px;
}

.blog-details__author-content h3, .blog-details__author-content p {
  margin: 0;
}

.blog-details__author-content h3 {
  color: #012237;
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
}

.blog-details__author-content p {
  font-size: 16px;
  line-height: 30px;
  color: #808080;
  margin-bottom: 0px;
}

.blog-details__author-content a {
  font-size: 16px;
  font-weight: 500;
  color: #f16101;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.blog-details__author-content a:hover {
  color: #012237;
}

.comment-one {
  margin-bottom: 60px;
}

.comment-one__single {
  border-bottom: 2px solid #f1f1f1;
  padding-bottom: 50px;
}

.comment-one__single+.comment-one__single {
  margin-top: 60px;
}

.comment-one__image, .comment-one__content {
  display: table-cell;
  vertical-align: top;
}

.comment-one__image {
  width: 170px;
}

.comment-one__image .inner-block {
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.comment-one__image .inner-block>img {
  width: 100%;
}

.comment-one__content {
  padding-left: 40px;
}

.comment-one__author {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #012237;
}

.comment-one__date {
  color: #f16101;
  font-size: 16px;
  font-weight: 500;
  line-height: 1em;
  margin: 0;
  margin-top: 15px;
  margin-bottom: 25px;
}

.comment-one__date-sep {
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
  margin-right: 12px;
}

.comment-one__text {
  color: #808080;
  font-size: 16px;
  line-height: 30px;
  margin: 0;
}

.comment-one__reply {
  font-size: 12px;
  padding: 0.5px 21px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.comment-one__reply:hover {
  background-color: #012237;
  color: #fff;
}

.comment-one__top-left, .comment-one__top-right {
  display: table-cell;
  vertical-align: top;
}

.reply-form input, .reply-form textarea {
  border: none;
  outline: none;
  width: 100%;
  display: block;
  height: 77px;
  color: #808080;
  font-size: 16px;
  padding-left: 30px;
  background-color: #f1f1f1;
  margin-bottom: 10px;
}

.reply-form input::-webkit-input-placeholder, .reply-form textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #808080;
}

.reply-form input::-moz-placeholder, .reply-form textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #808080;
}

.reply-form input:-ms-input-placeholder, .reply-form textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #808080;
}

.reply-form input:-moz-placeholder, .reply-form textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #808080;
}

.reply-form textarea {
  height: 210px;
  padding-top: 20px;
}

.reply-form__btn {
  font-size: 18px;
  padding: 21.5px 58px;
}

.reply-form__btn:hover {
  background-color: #012237;
  color: #fff;
}

.blog-two {
  padding-top: 110px;
  margin-bottom: 205px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #2da397;
}

.blog-two:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../images/patter-1-1.jpg);
  opacity: 0.05;
}

.blog-two__carousel {
  top: 205px;
  margin-top: -205px;
}

.blog-two .block-title__title {
  color: #fff;
}

.blog-two .owl-dots .owl-dot {
  outline: none;
}

.blog-two .owl-nav.disabled+.owl-dots {
  margin-top: 30px;
}

.blog-two .owl-dots .owl-dot:hover span, .blog-two .owl-dots .owl-dot.active span {
  -webkit-transform: scale(1.56);
  transform: scale(1.56);
  opacity: 1;
  background-color: #012237;
}

.blog-two .owl-dots .owl-dot span {
  width: 9px;
  height: 9px;
  background-color: #012237;
  margin: 0 2.5px;
  border-radius: 50%;
  opacity: 0.2;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

.blog-two__inner {
  padding: 40px;
  padding-top: 20px;
  background-color: rgba(1, 34, 55, 0.6);
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.blog-two__inner:hover {
  background-color: rgba(1, 34, 55, 0.3);
}

.blog-two__date {
  width: 70px;
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  color: #012237;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: .2em;
  font-weight: 600;
  border-radius: 50%;
  line-height: 1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  margin-bottom: 90px;
}

.blog-two__date::before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.blog-two__date:hover:before {
  left: 0;
}

.blog-two__date span {
  font-weight: 400;
  font-family: "Satisfy";
  font-size: 18px;
  letter-spacing: .0em;
}

.blog-two__title {
  margin: 0;
  color: #fff;
  font-weight: 700;
  font-size: 30px;
  margin-top: 15px;
}

.blog-two__title a {
  color: inherit;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.blog-two__title a:hover {
  text-decoration: underline;
}

.blog-two__meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: -10px;
}

.blog-two__meta a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.blog-two__meta a:hover {
  text-decoration: underline;
}

.blog-two__meta a+a:before {
  content: '.';
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-left: 5px;
  margin-right: 5px;
}

/*
* 15. search-popup styles
*/

.search-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transition: -webkit-transform .7s ease;
  transition: -webkit-transform .7s ease;
  transition: transform .7s ease;
  transition: transform .7s ease, -webkit-transform .7s ease;
}

.search-popup.active {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.search-popup__overlay {
  width: 100%;
  height: 100%;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  opacity: .7;
  cursor: none;
}

.search-popup__inner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-popup__form {
  position: relative;
  z-index: 9991;
  width: 100%;
  padding: 15px;
  max-width: 600px;
  position: relative;
}

.search-popup__form input {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 60px;
  color: #012237;
  font-size: 18px;
  background-color: white;
  padding-left: 30px;
}

.search-popup__form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #012237;
}

.search-popup__form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #012237;
}

.search-popup__form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #012237;
}

.search-popup__form input:-moz-placeholder {
  /* Firefox 18- */
  color: #012237;
}

.search-popup__form button[type=submit] {
  border: none;
  outline: none;
  width: 60px;
  height: 60px;
  color: #fff;
  background-color: #f16101;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.search-popup__form button[type=submit]:hover {
  background-color: #012237;
  color: #f16101;
}

/*
* 16. sidebar styles
*/

@media (max-width: 991px) {
  .sidebar {
    margin-top: 50px;
  }
}

.sidebar__title {
  margin: 0;
  color: #012237;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}

.sidebar__single:not(.sidebar__search) {
  padding: 50px;
  background-color: #f1f1f1;
}

.sidebar__single+.sidebar__single {
  margin-top: 30px;
}

.sidebar__post__single+.sidebar__post__single {
  margin-top: 30px;
}

.sidebar__post-image, .sidebar__post-content {
  display: table-cell;
  vertical-align: middle;
}

.sidebar__post-image {
  width: 60px;
}

.sidebar__post-image .inner-block {
  width: 100%;
}

.sidebar__post-image .inner-block>img {
  width: 100%;
}

.sidebar__post-content {
  padding-left: 20px;
}

.sidebar__post-title {
  margin: 0;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #012237;
}

.sidebar__post-title a {
  color: #012237;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.sidebar__post-title a:hover {
  color: #012237;
}

.sidebar__category-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar__category-list-item {
  line-height: 1em;
}

.sidebar__category-list-item a {
  color: #7B7F85;
  font-size: 16px;
  font-weight: 500;
  display: block;
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.sidebar__category-list-item a:before {
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
  color: #012237;
  font-weight: 900;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.sidebar__category-list-item a:hover {
  color: #012237;
}

.sidebar__category-list-item+.sidebar__category-list-item {
  margin-top: 27px;
}

.sidebar__tags-list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: -10px;
}

.sidebar__tags-list-item {
  display: inline-block;
  vertical-align: middle;
  line-height: 1em;
}

.sidebar__tags-list-item a {
  display: block;
  color: #7B7F85;
  font-size: 16px;
  font-weight: 500;
  line-height: 1em;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.sidebar__tags-list-item a:hover {
  color: #012237;
}

.sidebar__search-form {
  width: 100%;
  height: 79px;
  background-color: #f1f1f1;
  position: relative;
}

.sidebar__search-form input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #808080;
  padding-left: 40px;
  padding-right: 40px;
}

.sidebar__search-form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #808080;
}

.sidebar__search-form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #808080;
}

.sidebar__search-form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #808080;
}

.sidebar__search-form input:-moz-placeholder {
  /* Firefox 18- */
  color: #808080;
}

.sidebar__search-form button[type=submit] {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  background-color: #f16101;
  border: none;
  outline: none;
  position: absolute;
  top: 50%;
  right: 40px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.sidebar__search-form button[type=submit] i {
  font-size: 14px;
  color: #fff;
  line-height: 41px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.sidebar__search-form button[type=submit]:before {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #f16101;
  position: absolute;
  left: -5px;
  top: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.sidebar__search-form button[type=submit]:hover:before {
  left: 0;
}

/*
* 17. about styles
*/

.about-one {
  padding: 120px 0;
  position: relative;
  border-bottom: 2px solid #f1f1f1;
}

.about-one__circle {
  position: absolute;
  right: 10%;
  bottom: -3%;
}

.about-one__img {
  position: relative;
  margin-bottom: 45px;
}

.about-one__img:before {
  content: '';
  width: 197px;
  height: 268px;
  position: absolute;
  top: -40%;
  left: -10%;
  background-image: url(../images/dot-pattern-1-2.png);
  background-position: 0 0;
  z-index: 10;
}

.about-one__text {
  margin: 0;
  font-size: 18px;
  line-height: 36px;
  color: #808080;
  margin-bottom: 60px;
}

.about-one__btn {
  padding: 21.5px 68px;
  font-size: 16px;
}

.about-one__btn:hover {
  background-color: #012237;
  color: #fff;
}
.ideiaviolao{
  position: relative;
  bottom: -50%;
}
.about-one__review {
  background-color: #fff;
  max-width: 300px;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 33.5px 0;
}

.about-one__review-count {
  margin: 0;
  font-weight: 400;
  font-size: 30px;
  color: #012237;
  line-height: 1em;
}

.about-one__review-stars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.about-one__review-stars i {
  color: #f16101;
  font-size: 20px;
}

.about-one__review-stars i+i {
  margin-left: 10px;
}

.about-one__review-text {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .2em;
}
.letra h1{
  font-size: 172px !important;
  color: #466895 !important;
}
.instrumento-letra h1{
  font-size: 58px !important;
  color: #466895 !important;
}
.alfabeto a{
  font-size: 61px !important;
}
.alfabeto a.current{
  font-size: 61px !important;
  color: #cccccc !important;
}
.instrumento {
  position: relative;
  /* left: -20%; */
  text-transform: uppercase;
  z-index: 5;
}

.como-fazer-pesquisa {
  background-Color: "#6294d6";
  padding: 50px;
  background-color: rgb(249, 165, 51);
  padding: 50px;
  position: relative;
  top: -150px;
  z-index: -1
}
.como-fazer-pesquisa h1 {
  font-size: 60px!important;
  font-family: 'Fira Sans Condensed';
  font-weight: 700;
  color: #466895 !important;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  line-height: 59px;
}
.como-fazer-pesquisa h3 {
  color: #6f7072;
  font-family: 'Fira Sans Condensed';
  font-weight: 100;
  font-size: 36px;
  /* line-height: 44px; */
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
}
.about-one__about-page {
  border-bottom: 1px solid #f1f1f1;
}

.about-one__about-page .about-one__img:before {
  top: auto;
  bottom: -40%;
}

.about-two {
  padding: 120px 0;
}

.about-two .block-title {
  margin-bottom: 50px;
}

.about-two__text {
  margin: 0;
  font-size: 24px;
  line-height: 32px;
  color: #808080;
}

.about-two .thm-btn {
  padding: 21.5px 68px;
}

.about-two .thm-btn:hover {
  background-color: #f16101;
  color: #fff;
}

.about-two__single {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.about-two__single-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 45px;
  margin-bottom: 50px;
}

.about-two__single-text {
  color: #012237;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.about-two__single i {
  color: #2da397;
  font-size: 64px;
  margin-right: 14px;
}

/* .about-two__image {
  display: inline-block;
  border-radius: 50%;
  position: relative;
} */

.about-two__image_ {
  display: inline-block;
  top: -120px;
  position: relative;
}

.about-two__image:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 330px;
  height: 330px;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 20px solid #fff;
  z-index: 11;
}

.about-two__image:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -20px;
  border: 3px solid #f16101;
  border-radius: 50%;
}

.about-two__image>img {
  border-radius: 50%;
  position: relative;
  z-index: 10;
}

.about-two__image-dots {
  width: 197px;
  height: 268px;
  position: absolute;
  top: 0%;
  left: calc(100% - 60px);
  background-image: url(../images/dot-pattern-1-2.png);
  background-position: 0 0;
  z-index: 10;
}

.about-two__count {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 12;
  -webkit-transform: translateX(-20%);
  transform: translateX(-20%);
  border-radius: 50%;
  width: 175px;
  height: 175px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
}

.about-two__count:before {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: 1px dashed rgba(1, 34, 55, 0.15);
  border-radius: 50%;
}

.about-two__count-text {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: .2em;
  font-weight: 600;
  color: #808080;
}

.about-two__count span {
  display: block;
  color: #f16101;
  letter-spacing: 0em;
  font-weight: 400;
  font-size: 30px;
}

/*
* 18. video styles
*/

.video-one {
  background-color: #022c46;
  position: relative;
  padding: 120px 0;
}

.video-one .container {
  position: relative;
}

.video-one__scratch {
  position: absolute;
  top: -8%;
  left: -12%;
}

.video-one:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #022c46;
  background-image: url(../images/patter-1-1.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  opacity: 0.05;
}

.video-one__content {
  padding-bottom: 30px;
}

.video-one__title {
  font-size: 60px;
  color: #fff;
  font-weight: 700;
  letter-spacing: -0.04em;
  margin: 0;
}

.video-one__btn {
  margin-top: 35px;
  padding: 21.5px 59.5px;
  background-color: #012237;
  font-size: 18px;
  color: #fff;
}

.video-one__popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 104px;
  height: 104px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  right: 60px;
  bottom: 60px;
}

.video-one__popup:before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.video-one__popup i {
  font-size: 26px;
  color: #012237;
}

.video-one__popup:hover:before {
  left: 0;
}

.video-two {
  position: relative;
  padding: 120px 0;
  background-image: url(../images/video-bg-1-1.jpg);
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

.video-two::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #012237;
  opacity: 0.6;
}

.video-two .container {
  position: relative;
}

.video-two .thm-btn {
  padding: 21.5px 68px;
  background-color: #012237;
  color: #fff;
}

.video-two .thm-btn:hover {
  background-color: #fff;
  color: #012237;
}

.video-two__scratch {
  position: absolute;
  top: -20%;
  left: -12%;
}

.video-two__title {
  font-size: 60px;
  color: #fff;
  font-weight: 700;
  letter-spacing: -0.04em;
  margin: 0;
  margin-bottom: 60px;
}

.video-two__popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 156px;
  height: 156px;
  background-color: #f16101;
  border-radius: 50%;
  position: relative;
}

.video-two__popup:before {
  content: '';
  position: absolute;
  top: 0;
  left: -15px;
  width: 100%;
  height: 100%;
  border: 2px solid #f16101;
  border-radius: 50%;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.video-two__popup i {
  font-size: 38px;
  color: #fff;
}

.video-two__popup:hover:before {
  left: 0;
}

.video-three {
  padding-bottom: 120px;
}

.video-three .container {
  position: relative;
}

.video-three .container:before {
  content: '';
  width: 197px;
  height: 268px;
  position: absolute;
  bottom: -10%;
  left: -10%;
  background-image: url(../images/dot-pattern-1-2.png);
  background-position: 0 0;
}

.video-three__box {
  background-image: url(../images/video-1-2.jpg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 30px;
  padding: 60px;
}

.video-three__fact {
  padding: 65px 60px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.video-three__fact i {
  font-size: 64px;
  color: #fff;
}

.video-three__fact-count {
  font-size: 40px;
  font-weight: 400;
  color: #fff;
  margin: 0;
  line-height: 1em;
  margin-top: 20px;
}

.video-three__fact-text {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  line-height: 1em;
  margin-top: 5px;
}

.video-three__title {
  font-size: 50px;
  font-weight: bold;
  color: #012237;
  margin: 0;
}

.video-three__btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 215px;
}

.video-three__popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 104px;
  height: 104px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
}

.video-three__popup:before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.video-three__popup i {
  font-size: 26px;
  color: #012237;
}

.video-three__popup:hover:before {
  left: 0;
}

/*
* 19. brand styles
*/

.brand-one {
  padding: 120.5px 0;
}

.brand-one__home-three {
  border-top: 2px solid #f1f1f1;
}

.brand-one__carousel {
  padding-left: 45px;
  padding-right: 45px;
}

.brand-one__carousel img {
  opacity: 0.2;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.brand-one__carousel img:hover {
  opacity: 0.6;
}

.brand-two {
  padding: 120px 0;
  border-top: 2px solid #f1f1f1;
}

.brand-two .block-title__title {
  font-size: 24px;
  font-weight: bold;
  color: #012237;
  margin: 0;
}

/*
* 20. testimonials styles
*/

.testimonials-one {
  border-top: 2px solid #f1f1f1;
  padding: 120px 0;
}

.testimonials-one__carousel {
  text-align: center;
}

.testimonials-one__carousel .owl-item img {
  width: auto;
  display: inline-block;
}

.testimonials-one__carousel .owl-stage-outer {
  overflow: visible;
}

.testimonials-one__carousel .owl-dots .owl-dot {
  outline: none;
}

.testimonials-one__carousel .owl-nav.disabled+.owl-dots {
  margin-top: 30px;
}

.testimonials-one__carousel .owl-dots .owl-dot:hover span, .testimonials-one__carousel .owl-dots .owl-dot.active span {
  -webkit-transform: scale(1.56);
  transform: scale(1.56);
  opacity: 1;
  background-color: #012237;
}

.testimonials-one__carousel .owl-dots .owl-dot span {
  width: 9px;
  height: 9px;
  background-color: #012237;
  margin: 0 2.5px;
  border-radius: 50%;
  opacity: 0.2;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

.testimonials-one__single {
  border-style: solid;
  border-width: 2px;
  border-color: #f1f1f1;
  background-color: white;
  padding: 60px 55px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.testimonials-one__single:hover {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
}

.testimonials-one__qoute {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f16101;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.testimonials-one__qoute::before {
  content: '';
  position: absolute;
  top: 0;
  left: -5px;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 1px solid #f16101;
}

.testimonials-one__carousel .owl-item .testimonials-one__img {
  width: 58px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.testimonials-one__text {
  font-size: 16px;
  line-height: 34px;
  font-weight: 500;
  margin: 0;
  padding-bottom: 30px;
  margin-bottom: 40px;
  border-bottom: 1px solid #f1f1f1;
}

.testimonials-one__name {
  font-size: 18px;
  color: #012237;
  font-weight: 600;
  margin: 0;
  line-height: 1em;
  margin-bottom: 10px;
}

.testimonials-one__designation {
  margin: 0;
  line-height: 1em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: .2em;
  color: #808080;
}

.testimonials-one__home-three {
  border-top: 0;
  padding-bottom: 80px;
}

/*
* 21. meeting styles
*/

.meeting-one {
  padding-top: 120px;
  padding-bottom: 120px;
}

.meeting-one__block-text {
  margin: 0;
  font-size: 16px;
  line-height: 34px;
  font-weight: 500;
}

@media (min-width: 992px) {
  .meeting-one__content {
    padding-left: 60px;
  }
}

.meeting-one__box-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 115px;
}

.meeting-one__box {
  background-color: #f1f1f1;
  width: 200px;
  height: 221px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  position: relative;
}

.meeting-one__box img {
  border-radius: 50%;
}

.meeting-one__box+.meeting-one__box {
  margin-left: 50px;
  margin-top: 30px;
}

.meeting-one__title {
  margin: 0;
  color: #012237;
  font-weight: 600;
  font-size: 16px;
  line-height: 1em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.meeting-one__text {
  margin: 0;
  font-size: 14px;
  color: #808080;
  line-height: 1em;
}

.meeting-one__bubble {
  background-color: #2da397;
  position: absolute;
  bottom: calc(100% + 15px);
  right: -30px;
  color: #fff;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  text-align: left;
  padding: 15px 20px;
  max-width: 192px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(4, 5, 5, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(4, 5, 5, 0.1);
}

.meeting-one__bubble:before {
  content: '';
  width: 24px;
  height: 17px;
  position: absolute;
  top: 100%;
  left: 30px;
  background-color: #2da397;
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 0);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
}

.meeting-one__box:nth-child(even) .meeting-one__bubble:before, .meeting-one__box:nth-child(even) .meeting-one__bubble {
  background-color: #f16101;
}

/*
* 22. countdown styles
*/

.countdown-one {
  padding-bottom: 120px;
}

.countdown-one .become-teacher__form {
  margin-top: -87px;
}

.countdown-one__content {
  padding-top: 100px;
}

.countdown-one__title {
  margin: 0;
  color: #012237;
  font-size: 60px;
  font-weight: 700;
  margin-top: -15px;
}

.countdown-one__tag-line {
  margin: 0;
  font-size: 26px;
  color: #2da397;
  margin-top: 5px;
}

.countdown-one__text {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 34px;
  color: #808080;
  margin-top: 45px;
  margin-bottom: 50px;
}

.countdown-one__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-left: -7px;
  margin-right: -7px;
  margin-bottom: 0;
  margin-top: -10px;
}

.countdown-one__list li {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 25%;
  flex: 1 1 25%;
  max-width: 25%;
  padding-left: 7px;
  padding-right: 7px;
}

.countdown-one__list li div {
  border: 2px solid #f1f1f1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.countdown-one__list li i {
  line-height: 115px;
  color: #f16101;
  font-size: 40px;
  font-style: normal;
}

.countdown-one__list li span {
  background-color: #f1f1f1;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .2em;
  text-transform: uppercase;
  line-height: 41px;
}

/*
* 23. mailchimp styles
*/

.mailchimp-one {
  background-color: #6294d6;
  padding: 120px 0;
}

.mailchimp-one .my-auto {
  width: 100%;
}

.mailchimp-one__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mailchimp-one__icon i {
  color: #fff;
  font-size: 53px;
  margin-left: 10px;
  margin-right: 25px;
  width: 112px;
  height: 112px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #466895;
  position: relative;
}

.mailchimp-one__icon i:after {
  /* content: '';
  position: absolute;
  top: 0;
  left: -10px;
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-transition: all .4s ease;
  transition: all .4s ease; */
}

.mailchimp-one__icon i:hover:after {
  /* left: 0; */
}

.mailchimp-one__title {
  margin: 0;
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -.04em;
}

.mailchimp-one__form {
  position: relative;
}

.mailchimp-one__form input {
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  height: 77px;
  background-color: #fff;
  color: #808080;
  font-size: 16px;
  padding-left: 30px;
}

.mailchimp-one__form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #808080;
}

.mailchimp-one__form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #808080;
}

.mailchimp-one__form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #808080;
}

.mailchimp-one__form input:-moz-placeholder {
  /* Firefox 18- */
  color: #808080;
}

.mailchimp-one__form .thm-btn {
  background-color: #466895;
  color: #fff;
  padding: 0;
  line-height: 77px;
  width: 159px;
  position: absolute;
  top: 0;
  right: 0;
}

.mailchimp-one__form .thm-btn:hover {
  color: #fff;
  background-color: #f16101;
}

.Toastify__toast-container {
  background: #E5E5E5;
  height: 100%;
}

.Toastify__toast-container {
  width: 100% !important;
}

.Toastify__toast {
  border-radius: 20px 20px 0 0 !important;
  text-align: center;
}

.Toastify__toast {
  bottom: 0;
  position: absolute !important;
  /* border: 1px solid red; */
  left: 0;
  width: 100%;
  margin-bottom: 0px !important;
}

.Toastify__toast--success {
  background-color: #f2f2f7 !important;
}

.feedback {
  background-color: #FFFFFF;
  border-radius: 20px;
  height: 300px;
}

.feedback-titulo {
  color: #5D5B68;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.feedback-texto {
  color: #5D5B68;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  white-space: break-spaces;
  text-align: center;
}
